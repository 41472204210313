import React from 'react';


const Close= () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="16.294" height="16.294" viewBox="0 0 16.294 16.294">
                <g id="np_x_2663763_000000" transform="translate(-28.125 -28.129)">
                    <path id="Path_10046" data-name="Path 10046" d="M29.289,44.423a1.163,1.163,0,0,1-.823-1.986L42.433,28.469a1.164,1.164,0,0,1,1.646,1.646L30.111,44.083a1.158,1.158,0,0,1-.822.34Z" transform="translate(0 0)" fill="#414042"/>
                    <path id="Path_10047" data-name="Path 10047" d="M43.257,44.423a1.163,1.163,0,0,1-.823-.34L28.465,30.115a1.164,1.164,0,1,1,1.646-1.646L44.079,42.437a1.163,1.163,0,0,1-.822,1.986Z" transform="translate(0 0)" fill="#414042"/>
                </g>
            </svg>
        </>
    )
}
export default Close;