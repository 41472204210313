import React from 'react';


const Cart= () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="22.056" height="21.122" viewBox="0 0 22.056 21.122">
                <g id="cart" transform="translate(-0.75 -0.75)">
                    <path id="Path_12111" data-name="Path 12111" d="M13.869,30.934A.934.934,0,1,1,12.934,30,.934.934,0,0,1,13.869,30.934Z" transform="translate(-3.959 -10.747)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_12112" data-name="Path 12112" d="M30.369,30.934A.934.934,0,1,1,29.434,30,.934.934,0,0,1,30.369,30.934Z" transform="translate(-10.181 -10.747)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Path_12113" data-name="Path 12113" d="M1.5,1.5H5.237l2.5,12.511a1.869,1.869,0,0,0,1.869,1.5h9.082a1.869,1.869,0,0,0,1.869-1.5l1.495-7.839H6.172" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
            </svg>
        </>
    )
}
export default Cart;