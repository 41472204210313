import { combineReducers } from 'redux';

import api from './api';
// import auth from './auth';
import { authReducer } from './authReducer';
import { productReducer } from './productReducer';
import { utilsReducer } from './utilsReducer';

const rootReducer = combineReducers({
    api,
    // auth,
    userUtils: utilsReducer,
    product: productReducer,
    auth: authReducer
})

export default rootReducer;