import { removeMobileFomrmatter } from ".";

export const login = value => {
    let error = {};

    if (!value.email) { error.email = 'Email is required' }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(value.email)) {
        error.email = "Please enter valid email address."
    }
    if (!value.password) { error.password = 'Password is required' }
    return error;
}

export const CreateAcc = value => {
    let error = {};

    if (!value.firstName || !value.firstName.trim()) {
        error.firstName = 'Please enter first name'
    }
    if (!value.lastName || !value.lastName.trim()) {
        error.lastName = 'Please enter last name'
    }
    if (!value.phoneNumber || !value.phoneNumber.trim()) {
        error.phoneNumber = "Please enter phone number."
    }
    else if (removeMobileFomrmatter(value.phoneNumber, true).length !== 10) {
        error.phoneNumber = "Should not be less than 10 digits."
    }
    // if (!value.email) { error.email = 'Please enter email' }
    if (value.email && !/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(value.email)) {
        error.email = "Please enter valid email address."
    }

    return error;
}

export const SigninValidate = value => {
    let error = {};

    if (!value.phoneNumber || !value.phoneNumber.trim()) {
        error.phoneNumber = "Please enter phone number."
    }
    else if (removeMobileFomrmatter(value.phoneNumber, true).length !== 10) {
        error.phoneNumber = "Should not be less than 10 digits."
    }
    return error;
}


export const Profile = value => {
    let error = {};

    if (!value.firstName || !value.firstName.trim()) {
        error.firstName = 'Please enter first name'
    }
    if (!value.lastName || !value.lastName.trim()) {
        error.lastName = 'Please enter last name'
    }
    if (!value.phoneNumber || !value.phoneNumber.trim()) {
        error.phoneNumber = "Please enter phone number."
    }
    if (value.email && (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(value.email))) {
        error.email = "Please enter valid email address."
    }

    return error;
}

export const Address = (value, activePincodes = []) => {
    let error = {};

    if (!value.firstName || !value.firstName.trim()) {
        error.firstName = 'Please enter first name'
    }
    if (!value.lastName || !value.lastName.trim()) {
        error.lastName = 'Please enter last name'
    }
    if (!value.pincode || !value.pincode.trim()) {
        error.pincode = "Please enter pincode"
    }
    else if (value.pincode.replace(/\D+/g, "").trim().length !== 6) {
        error.pincode = "Pincode should be 6 digits"
    }
    else if (!activePincodes.includes((value.pincode).toString())) {
        error.pincode = "We are not delivering at this pincode"
    }
    if (!value.apartment || !value.apartment.trim()) {
        error.apartment = "Please enter apartment name"
    }
    if (!value.city || !value.city.trim()) {
        error.city = "Please enter city"
    }
    if (!value.street || !value.street.trim()) {
        error.street = "Please enter locality"
    }
    if (!value.streetAddress2 || !value.streetAddress2.trim()) {
        error.streetAddress2 = "Please enter wing/floor/flat/house no"
    }
    if (!value.phoneNumber || !value.phoneNumber.trim()) {
        error.phoneNumber = "Please enter phone number"
    }
    else if (removeMobileFomrmatter(value.phoneNumber, true).length !== 10) {
        error.phoneNumber = "Should not be less than 10 digits"
    }

    return error;
}

export const paymentCard = value => {
    let error = {};

    if (!value.name || !value.name.trim()) {
        error.name = 'Please enter holder name'
    }
    if (!value.cvv || !value.cvv.trim()) {
        error.cvv = 'Please enter cvv'
    }
    let chckNumber = value.cardNumber && value.cardNumber.trim();
    if (!value.cardNumber) { error.cardNumber = 'Please enter card number' }
    else if (chckNumber.length < 19) {
        error.cardNumber = 'Please enter card number'
    }
    let chckDate = value.date && value.date.trim();
    if (!value.date) { error.date = 'Please enter exp date' }
    else if (chckDate.length < 5) {
        error.date = 'Please enter exp date'
    }

    let chckCvv = value.cvv && value.cvv.trim();
    if (!value.cvv) { error.cvv = 'Please enter cvv' }
    else if (chckCvv.length < 3) {
        error.cvv = 'Please enter cvv'
    }

    return error;
}

export const changePassword = value => {
    let error = {};

    if (!value.password) { error.password = 'Enter current password' }
    if (!value.newPassword) { error.newPassword = 'Enter new password' }
    else if (value.newPassword === value.password) { error.newPassword = 'Current password and new password can not be same' }
    else if (value.newPassword.length < 6) { error.newPassword = "New password must contain at least 6 characters" }
    if (!value.repeatNewPassword) { error.repeatNewPassword = 'Re enter confirm password' }
    if (value.repeatNewPassword !== value.newPassword) { error.repeatNewPassword = 'New password and confirm password must be same' }

    return error;
}


export const resetPassword = value => {
    let error = {};

    if (!value.password) { error.password = 'Enter new Password' }
    else if (value.password.length < 6) { error.password = "New password must contain at least 6 characters" }
    if (!value.repeatNewPassword) { error.repeatNewPassword = 'Renter confirm Password' }
    if (value.repeatNewPassword !== value.password) { error.repeatNewPassword = 'New password and confirm password must be same' }

    return error;
}

export const FaqForm = value => {
    let error = {};

    if (!value.question || !value.question.trim()) {
        error.question = 'Please write your issue'
    }
    if (!value.categoryId) {
        error.categoryId = 'Please select Reason'
    }
    if (!value.mobileNumber || !value.mobileNumber.trim()) {
        error.mobileNumber = "Please enter phone number."
    }
    else if (removeMobileFomrmatter(value.mobileNumber, true).length !== 10) {
        error.mobileNumber = "Should not be less than 10 digits."
    }

    return error;
}