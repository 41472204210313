import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getNotificationCount } from "../../../redux/actions/utilsAction";
// import { getNotificationCount } from '../../../redux/actions/utilsAction';
import { VALID_LOCATION_ERROR, VALID_LOCATION_SUCCESS } from '../../../constants/types';
import { routes } from "../../../navigator/mobileRoutes";
import store from "../../../redux/store";
import Footer from '../common/footer';
import Header from "../common/header";
import LoaderBox from '../common/Loader';
import firebase from 'firebase/app'
import { getToken } from '../../../firebase';


const Layout = (props) => {

    const { location } = props.history;

    const fcmToken = JSON.parse(localStorage.getItem("FCMToken"));
    const validLocation = JSON.parse(localStorage.getItem("validLocation"));

    const dispatch = useDispatch();

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const userData = useSelector(state => state.auth.userData)
    const isValidLocation = useSelector(state => state.auth.isValidLocation)
    const loader = useSelector(state => state.auth.loader);

    if (validLocation) {
        store.dispatch({ type: VALID_LOCATION_SUCCESS, payload: validLocation })
    }
    else {
        store.dispatch({ type: VALID_LOCATION_ERROR })
    }

    const [locationModal, setLocationModal] = useState(false);
    const [isRender, setisRender] = useState(false)
    const [ispayment, setpayment] = useState(false)
    const [isFooter, setFooter] = useState(false)
    // console.log('isFooter: ', isFooter);
    const [isproduct, setproduct] = useState(false)

    useEffect(() => {

        if (location.pathname.startsWith('/product_list')) {
            setisRender(false)
        }
        else {
            setisRender(true)
        }
        if (location.pathname.startsWith('/product_list')) {
            setproduct(true)
        }
        else {
            setproduct(false)
        }

        if (location.pathname.startsWith('/payment_method')) {
            setpayment(true)
        }
        else {
            setpayment(false)
        }

        if (location.pathname === '/' || (location.pathname.startsWith('/product_list')) ||
            (location.pathname.startsWith('/cms/tc')) || (location.pathname.startsWith('/cms/pp'))
            || (location.pathname.startsWith('/cms/rrd')) || (location.pathname.startsWith('/about_clefill')) ||
            (location.pathname.startsWith('/cms/sp')) ||
            (location.pathname.startsWith('/contactus'))) {
            setFooter(true)
        }
        else {
            setFooter(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    useEffect(() => {
        const abortController = new AbortController()
        dispatch(getNotificationCount())

        return () => {
            abortController.abort();
        }
    }, [dispatch])

    useEffect(() => {
        if (firebase.messaging.isSupported() && !isLoggedIn) {
            if (!fcmToken)
                getToken()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn])

    return (
        <>
            <main className="app-main">
                <Header />
                {loader && <LoaderBox />}
                <section className={`section-area ${isRender ? ' add_section-area' : ''} ${ispayment ? ' section-main-payment' : ''} ${isproduct ? 'section-main-product' : ''} `}>
                    <Switch>
                        {routes.map((route, idx) => {
                            return route.element ? (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    render={props => (
                                        <route.element {...props}
                                            isLoggedIn={isLoggedIn}
                                            dispatch={dispatch}
                                            setLocationModal={setLocationModal}
                                            locationModal={locationModal}
                                            customerId={userData?.id}
                                        />
                                    )}
                                />
                            ) : (null)
                        })}
                        <Redirect to={`${isValidLocation ? '/product_list' : '/'}`} />
                    </Switch>
                </section>
                {isFooter ? <Footer setLocationModal={setLocationModal}
                    locationModal={locationModal} /> : ''}
            </main>
        </>
    )
}

export default Layout;