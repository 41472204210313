import React from 'react';


const Logo= () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 120 34.234">
                <defs>
                    <linearGradient id="linear-gradient" y1="0.502" x2="1" y2="0.502" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#f48220"/>
                    <stop offset="0.532" stopColor="#e90e8b"/>
                    <stop offset="1" stopColor="#0c72ba"/>
                    </linearGradient>
                </defs>
                <g id="Group_6448" data-name="Group 6448" transform="translate(904 288)">
                    <g id="Group_5215" data-name="Group 5215" transform="translate(-904 -286.495)">
                    <path id="Path_12154" data-name="Path 12154" d="M274.379,537.8a8.8,8.8,0,0,1-.679.856l4.912,4.33c.323-.372.63-.743.921-1.147Z" transform="translate(-250.253 -516.068)" fill="#414042"/>
                    <g id="Group_5214" data-name="Group 5214" transform="translate(0 0)">
                        <path id="Path_12155" data-name="Path 12155" d="M148.377,428.469a9.48,9.48,0,0,1-2.036.565,9.659,9.659,0,1,1,5.106-16.512l-1.357,1.874,7.561-1.228-1.228-7.561-1.131,1.567a16.193,16.193,0,1,0-2.052,26.109Z" transform="translate(-128.581 -403.296)" fill="#414042"/>
                        <path id="Path_12156" data-name="Path 12156" d="M259,550l4.75,4.7a16.322,16.322,0,0,0,1.616-1.357L260.422,549A10.743,10.743,0,0,1,259,550Z" transform="translate(-237.928 -525.459)" fill="#414042"/>
                    </g>
                    </g>
                    <path id="Path_12157" data-name="Path 12157" d="M423.667,471.687l7.125-.695a9.961,9.961,0,0,1-3.361,6.156,12.4,12.4,0,0,1-7.061,2.747,11.04,11.04,0,0,1-8.644-2.391c-2.246-1.939-3.555-4.734-3.91-8.418q-.557-5.623,2.214-9.209a10.9,10.9,0,0,1,8-4.088,11.026,11.026,0,0,1,8.805,2.5q3.417,3.029,3.975,8.9c.032.356.065.63.065.824.016.194.016.356.016.485l-15.317,1.486a5.673,5.673,0,0,0,1.39,3.555,3.711,3.711,0,0,0,3.151.969,4.484,4.484,0,0,0,2.375-.856A3.2,3.2,0,0,0,423.667,471.687Zm-8.708-5.816,8.434-.84a5.083,5.083,0,0,0-1.486-3.312,3.982,3.982,0,0,0-3.167-.937,3.746,3.746,0,0,0-2.811,1.486A5.994,5.994,0,0,0,414.959,465.87Z" transform="translate(-1266.624 -733.736)" fill="#414042"/>
                    <path id="Path_12158" data-name="Path 12158" d="M564.547,440.17V422.559H561.3V417.97h3.247c-.016-.178-.016-.372-.032-.582s-.016-.517-.016-.921c0-2.65.614-4.54,1.826-5.671,1.228-1.131,3.247-1.7,6.107-1.7.4,0,.889.016,1.438.032s1.163.065,1.826.113l-.016,4.96c-.113-.016-.242-.016-.4-.032-.162,0-.372-.016-.63-.016a2.593,2.593,0,0,0-2.068.646,4.126,4.126,0,0,0-.549,2.521v.646h3.684v4.589h-3.684v17.627h-7.481Z" transform="translate(-1395.386 -694.658)" fill="#414042"/>
                    <path id="Path_12159" data-name="Path 12159" d="M747,441.427V410.6h7.448v30.827Z" transform="translate(-1551.083 -695.915)" fill="#414042"/>
                    <path id="Path_12160" data-name="Path 12160" d="M825.2,441.427V410.6h7.448v30.827Z" transform="translate(-1616.648 -695.915)" fill="#414042"/>
                    <path id="Path_12161" data-name="Path 12161" d="M679.458,414.529a3.829,3.829,0,1,0-3.829,3.829A3.827,3.827,0,0,0,679.458,414.529Z" transform="translate(-1488.033 -695.999)" fill="#414042"/>
                    <path id="Path_12162" data-name="Path 12162" d="M676.116,473.728a6.132,6.132,0,0,0,3.7-1.228v20.826H672.4V472.5A6.324,6.324,0,0,0,676.116,473.728Z" transform="translate(-1488.536 -747.814)" fill="#414042"/>
                    <path id="Path_12163" data-name="Path 12163" d="M407.9,401.548a16.387,16.387,0,0,1,12.021-1.2c6.366,2.068,8.208,1.309,11.035-.194V394.52c-2.828,1.5-4.8,2.31-11.035.178-6.285-2.133-12.021,1.2-12.021,1.2" transform="translate(-1266.771 -681.984)" fill="url(#linear-gradient)"/>
                    <path id="Path_12164" data-name="Path 12164" d="M337.4,441.427V410.6h7.448v30.827Z" transform="translate(-1207.661 -695.915)" fill="#414042"/>
                </g>
            </svg>
        </>
    )
}
export default Logo;