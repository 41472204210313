import React from 'react';


const  Iconlocation= () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="14.395" height="16.552" viewBox="0 0 14.395 16.552">
                <g id="Group_6446" data-name="Group 6446" transform="translate(-144.456 -611.148)">
                    <path id="np_location-pin_3064564_000000" d="M13.583,0a6.686,6.686,0,0,1,6.674,6.721c0,3.708-3.476,6.4-6.164,8.622a.834.834,0,0,1-1.02,0c-2.735-2.225-6.211-4.913-6.211-8.622A6.726,6.726,0,0,1,13.583,0Z" transform="translate(138.094 611.648)" fill="none" stroke="#ffffff" strokeWidth="1"/>
                    <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(148 615)" fill="none" stroke="#ffffff" strokeWidth="1">
                    <circle cx="3.5" cy="3.5" r="3.5" stroke="none"/>
                    <circle cx="3.5" cy="3.5" r="3" fill="none"/>
                    </g>
                </g>
            </svg>

        </>
    )
}
export default  Iconlocation;