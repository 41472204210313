import React from 'react';


const Right= () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="45.667" height="33.665" viewBox="0 0 45.667 33.665">
                <path id="np_checkmark_1738980_000000" d="M72.592,36a3.607,3.607,0,0,0-2.138,1.088l-24.12,24.12-9.49-7.577a3.6,3.6,0,1,0-4.5,5.627l12,9.6a3.6,3.6,0,0,0,4.8-.3l13.9-13.9L75.555,42.157A3.6,3.6,0,0,0,72.592,36Z" transform="translate(-30.976 -35.977)" fill="#fff"/>
            </svg>

        </>
    )
}
export default Right;