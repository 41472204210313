import React from 'react';
import { useSelector } from 'react-redux';
import Facebook from '../../../assets/svg/facebook';
import Instagram from '../../../assets/svg/instagram';
import Linkedin from '../../../assets/svg/linkedin';
import { IoIosCall } from "react-icons/io";
import { AiOutlineMail } from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import Twitter from '../../../assets/svg/twitter';
import CLefill from "../../../assets/svg/footer-logo";
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';


const Footer = ({ setLocationModal }) => {

    const isValidLocation = useSelector(state => state.auth.isValidLocation)
    const history = useHistory()

    const handleRedirect = (path) => {
        if (!isValidLocation) {
            swal('Location unavailable !', 'Please select location !', 'error')
        } else {
            history.push(path)
        }
    }

    return (
        <>
            <footer>
                <div className="bottom-footer web-list-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 order-1 order-sm-0">                                
                                <div className="clf-footer-inner">                                    
                                    <div className="clf-social-icon">
                                        <h3 className="clf-footer-title">Let's Socialize</h3>
                                        <ul className='social-listing'>
                                            <li><a href="https://www.facebook.com/profile.php?id=100087878525201&mibextid=LQQJ4d" target="_blank" rel="noreferrer"><Facebook /></a></li>
                                            <li><a href="https://instagram.com/clefillproducts?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer"><Instagram /></a></li>
                                            <li><a href="https://twitter.com/clefillproducts?s=11&t=kZCCypuuVfaW2pIJHXkQYA" target="_blank" rel="noreferrer"><Twitter /></a></li>
                                            <li><a href="https://www.linkedin.com/company/clefill/" target="_blank" rel="noreferrer"><Linkedin /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-5 col-lg-5 clf-footer-link">
                                <h3 className="clf-footer-title">Shop</h3>
                                <ul>
                                    <div onClick={() => handleRedirect('/product_list/prefill/all')}><li>Home Care</li></div>
                                    <div onClick={() => handleRedirect('/product_list/prefill/laundry-care')}><li>Laundry Care</li></div>
                                    <div onClick={() => handleRedirect('/product_list/prefill/hand-wash')}><li>Hand Care</li></div>
                                    <div onClick={() => handleRedirect('/product_list/refill/all')}><li>Refill</li></div>
                                    <div onClick={() => handleRedirect('/about_clefill')}><li>Our Story</li></div>
                                </ul>
                            </div>
                            <div className="col-6 col-sm-6 col-md-3 col-lg-3 clf-footer-link">
                                <h3 className="clf-footer-title">Quick Link</h3>
                                <ul>
                                    <div onClick={() => handleRedirect('/cms/tc')}><li>Terms & Conditions</li></div>
                                    <div onClick={() => handleRedirect('/cms/rrd')}><li>Pricing, Delivery, Return and Refund Policy</li></div>
                                    <div onClick={() => handleRedirect('/cms/pp')}><li>Privacy Policy</li></div>
                                    <div onClick={() => handleRedirect('/contactus')}><li>Contact Us</li></div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="text-center copyright-text">Copyright © 2023 Cleanteligent solution pvt ltd. All Rights Reserved.</div>
                </div>
            </footer>

        </>
    );
}

export default Footer;