import { lazy } from 'react';

//mobile routes here
const Home = lazy(() => import('../mobile/pages/home'));
const Product_list = lazy(() => import('../mobile/pages/product/product_list'));
const Product_detail = lazy(() => import('../mobile/pages/product/product_detail'));
const Cart = lazy(() => import('../mobile/pages/cart/cart'));
const Address = lazy(() => import('../mobile/pages/profile/AllAddress'));
const Add_address = lazy(() => import('../mobile/pages/profile/AddAddress'));
const Edit_address = lazy(() => import('../mobile/pages/profile/EditAddress'));
const Payment_method = lazy(() => import('../mobile/pages/payment/PaymentMethod'));
const Add_card = lazy(() => import('../mobile/pages/payment/AddCard'));
const Edit_card = lazy(() => import('../mobile/pages/payment/EditCard'));
const Success_msg = lazy(() => import('../mobile/pages/success _msg'));
const Cart_clear = lazy(() => import('../mobile/pages/cart/EmptyCart'));
const Profile = lazy(() => import('../mobile/pages/profile/profile'));
// const Payment = lazy(() => import('./mobile/pages/payment/payment'));
const Card_list = lazy(() => import('../mobile/pages/payment/CardList'));
const Order_list = lazy(() => import('../mobile/pages/order/order_list'));
const Order_detail = lazy(() => import('../mobile/pages/order/order_details'));
const Map = lazy(() => import('../mobile/pages/order/LiveTrack'));
const Refund = lazy(() => import('../mobile/pages/order/refund'));
const Return = lazy(() => import('../mobile/pages/order/return'));
const Promocode = lazy(() => import('../mobile/pages/promocode'));
const Referralcode = lazy(() => import('../mobile/pages/referralcode'));
const Notifications = lazy(() => import('../mobile/pages/notifications'));
const Help_add = lazy(() => import('../mobile/pages/help/help_add'));
const FAQ = lazy(() => import('../mobile/pages/help/faq'));
const More_help = lazy(() => import('../mobile/pages/help/more_help'));
const Help_list = lazy(() => import('../mobile/pages/help/help_list'));
const CMSMobile = lazy(() => import('../mobile/pages/CMS'));
const ContactUsMobile = lazy(() => import('../mobile/pages/ContactUs'));
const Order_add = lazy(() => import('../mobile/pages/order/add_new_order'));
const AboutClefill = lazy(() => import('../mobile/pages/aboutClefill'));

//Mobile App Routes
export const routes = [

    { path: "/", exact: true, name: "Home", element: Home },
    { path: "/product_list/:catId/:typeId", name: "Product_list", element: Product_list },
    // { path: "/product_list/", exact: true, name: "Product_list", element: Product_list },
    { path: "/cms/:key", exact: true, name: "CMS", element: CMSMobile },
    { path: "/product_detail/:catId/:id", exact: true, name: "Product_detail", element: Product_detail },
    { path: "/cart", exact: true, name: "cartproduct_detail", element: Cart },
    { path: "/shipping_address", exact: true, name: "Address", element: Address },
    { path: "/my_address", exact: true, name: "Address", element: Address },
    { path: "/add_address", exact: true, name: "Add_address", element: Add_address },
    { path: "/edit_address/:id", exact: true, name: "Add_address", element: Edit_address },
    { path: "/payment_method", exact: true, name: "Payment_method", element: Payment_method },
    { path: "/add_card", exact: true, name: "Add_card", element: Add_card },
    { path: "/edit_card", exact: true, name: "Edit_card", element: Edit_card },
    { path: "/success/:id", exact: true, name: "Success", element: Success_msg },
    { path: "/cart_clear", exact: true, name: "Cart_clear", element: Cart_clear },
    { path: "/profile", exact: true, name: "Profile", element: Profile },
    // { path: "/payment", exact: true, name: "Payment", element: Payment },
    { path: "/card_list", exact: true, name: "Card_list", element: Card_list },
    { path: "/order_list", exact: true, name: "Order_list", element: Order_list },
    { path: "/order_detail/:id", exact: true, name: "Order Detail", element: Order_detail },
    { path: "/live_tracking/:id", exact: false, name: "Live tracking", element: Map },
    { path: "/refund", exact: true, name: "Refund", element: Refund },
    { path: "/return/:id", exact: true, name: "Return Order", element: Return },
    { path: "/promocode", exact: true, name: "Promocode", element: Promocode },
    { path: "/referralcode", exact: true, name: "Referralcode", element: Referralcode },
    { path: "/notifications", exact: true, name: "Notifications", element: Notifications },
    { path: "/Help-List", exact: true, name: "Help_add", element: Help_add },
    { path: "/faq", exact: true, name: "FAQ", element: FAQ },
    { path: "/about_clefill", exact: true, name: "FAQ", element: AboutClefill },
    { path: "/more_help", exact: true, name: "More_help", element: More_help },
    { path: "/help_list", exact: true, name: "Help_list", element: Help_list },
    { path: "/order_add", exact: true, name: "Order_add", element: Order_add },
    { path: "/contactUs", exact: true, name: "Contact Us", element: ContactUsMobile },
    { path: "*", exact: false, name: "Home", element: Home },
]