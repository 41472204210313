import React from 'react';


const Footer_logo= () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="74.333" height="21.206" viewBox="0 0 74.333 21.206">
                <defs>
                    <linearGradient id="linear-gradient" y1="0.502" x2="1" y2="0.502" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#f48220"/>
                    <stop offset="0.532" stopColor="#e90e8b"/>
                    <stop offset="1" stopColor="#0c72ba"/>
                    </linearGradient>
                </defs>
                <g id="Group_6447" data-name="Group 6447" transform="translate(904 288)">
                    <g id="Group_5215" data-name="Group 5215" transform="translate(-904 -287.068)">
                    <path id="Path_12154" data-name="Path 12154" d="M274.12,537.8a5.442,5.442,0,0,1-.42.53l3.043,2.682c.2-.23.39-.46.57-.711Z" transform="translate(-259.176 -524.338)" fill="#fff"/>
                    <g id="Group_5214" data-name="Group 5214" transform="translate(0 0)">
                        <path id="Path_12155" data-name="Path 12155" d="M140.843,418.889a5.869,5.869,0,0,1-1.261.35,5.983,5.983,0,1,1,3.163-10.228l-.841,1.161,4.684-.761-.761-4.684-.7.971a10.031,10.031,0,1,0-1.271,16.173Z" transform="translate(-128.582 -403.296)" fill="#fff"/>
                        <path id="Path_12156" data-name="Path 12156" d="M259,549.62l2.942,2.912a10.119,10.119,0,0,0,1-.841L259.881,549A6.655,6.655,0,0,1,259,549.62Z" transform="translate(-245.947 -534.418)" fill="#fff"/>
                    </g>
                    </g>
                    <path id="Path_12157" data-name="Path 12157" d="M417.6,465.606l4.414-.43a6.17,6.17,0,0,1-2.082,3.813,7.683,7.683,0,0,1-4.374,1.7,6.839,6.839,0,0,1-5.354-1.481,7.519,7.519,0,0,1-2.422-5.214,7.76,7.76,0,0,1,1.371-5.7,6.751,6.751,0,0,1,4.954-2.532,6.83,6.83,0,0,1,5.455,1.551,8.067,8.067,0,0,1,2.462,5.515c.02.22.04.39.04.51s.01.22.01.3l-9.488.921a3.514,3.514,0,0,0,.861,2.2,2.3,2.3,0,0,0,1.952.6,2.778,2.778,0,0,0,1.471-.53A1.981,1.981,0,0,0,417.6,465.606Zm-5.394-3.6,5.224-.52a3.149,3.149,0,0,0-.921-2.052,2.466,2.466,0,0,0-1.962-.581,2.32,2.32,0,0,0-1.741.921A3.713,3.713,0,0,0,412.206,462Z" transform="translate(-1283.787 -737.531)" fill="#fff"/>
                    <path id="Path_12158" data-name="Path 12158" d="M563.312,428.346V417.437H561.3v-2.842h2.012c-.01-.11-.01-.23-.02-.36s-.01-.32-.01-.57a4.639,4.639,0,0,1,1.131-3.513A5.5,5.5,0,0,1,568.2,409.1c.25,0,.551.01.891.02s.721.04,1.131.07l-.01,3.073c-.07-.01-.15-.01-.25-.02s-.23-.01-.39-.01a1.606,1.606,0,0,0-1.281.4,2.556,2.556,0,0,0-.34,1.561v.4h2.282v2.842h-2.282v10.919h-4.634Z" transform="translate(-1421.992 -695.587)" fill="#fff"/>
                    <path id="Path_12159" data-name="Path 12159" d="M747,429.7V410.6h4.614v19.1Z" transform="translate(-1589.107 -696.937)" fill="#fff"/>
                    <path id="Path_12160" data-name="Path 12160" d="M825.2,429.7V410.6h4.614v19.1Z" transform="translate(-1659.48 -696.937)" fill="#fff"/>
                    <path id="Path_12161" data-name="Path 12161" d="M676.544,413.072a2.372,2.372,0,1,0-2.372,2.372A2.371,2.371,0,0,0,676.544,413.072Z" transform="translate(-1521.433 -697.027)" fill="#fff"/>
                    <path id="Path_12162" data-name="Path 12162" d="M674.7,473.261a3.8,3.8,0,0,0,2.292-.761v12.9H672.4V472.5A3.917,3.917,0,0,0,674.7,473.261Z" transform="translate(-1521.973 -752.642)" fill="#fff"/>
                    <path id="Path_12163" data-name="Path 12163" d="M407.9,398.67a10.151,10.151,0,0,1,7.446-.741c3.943,1.281,5.084.811,6.836-.12v-3.493c-1.751.931-2.972,1.431-6.836.11a9.853,9.853,0,0,0-7.446.741" transform="translate(-1283.945 -681.984)" fill="url(#linear-gradient)"/>
                    <path id="Path_12164" data-name="Path 12164" d="M337.4,429.7V410.6h4.614v19.1Z" transform="translate(-1220.501 -696.937)" fill="#fff"/>
                </g>
            </svg>

        </>
    )
}
export default Footer_logo;