import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../../assets/svg/logo';
import { FaBars, FaTimes } from 'react-icons/fa'
import { BsCart2 } from 'react-icons/bs'
import { IoIosArrowForward } from 'react-icons/io'
import { RxCross2 } from "react-icons/rx";
import { CgSearch } from "react-icons/cg";
import { MdOutlineNotificationsNone } from 'react-icons/md'
import { Link, NavLink } from 'react-router-dom';
import { BiUser } from 'react-icons/bi';
import OutSideClick from '../../../utils/OutSideClick'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BackArrow from '../../../assets/svg/back_arrow';
import { GET_CART_COUNT_LOCAL_ERROR, GET_CART_COUNT_LOCAL_SUCCESS, GET_CART_COUNT_SUCCESS, SHLD_REDIRECT_TO_CART, VALID_LOCATION_SUCCESS } from '../../../constants/types';
import { checkLocation, doLogout, getProfile } from '../../../redux/actions/authAction';
import { getCartCount, productList, visitorCall } from '../../../redux/actions/productAction';
import NumberFormat from 'react-number-format';
import Close from '../../../assets/svg/close';
import swal from 'sweetalert';
import { pincodeFromLatLng, removeQuotsFun } from '../../../utils';
import LoaderBox from './Loader';
import axios from 'axios';

const Header = ({ }) => {

    const dropDownRef = useRef();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const isValidLocation = useSelector(state => state.auth.isValidLocation)
    const profileData = useSelector(state => state.auth.userProfile?.profile)
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const cartCountLocal = useSelector(state => state.product.cartCountLocal)
    const authData = useSelector(state => state.auth)
    const cartCount = useSelector(state => state.product.cartCount)
    const notificationCount = useSelector(state => state.userUtils.notificationCount)
    const allProductList = useSelector(state => state.product.productList)

    const [showDropDown, setshowDropDown] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    // const [headerTitle, setheaderTitle] = useState('Clefill');
    const [locationModal, setLocationModal] = useState(false);
    const [activeClass, setActiveClass] = useState(false);
    const [activeSearch, setActiveSearch] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [keyword, setKeyword] = useState('')
    const deviceIp = JSON.parse(localStorage.getItem("deviceIp"));
    const sessionDeviceIp = JSON.parse(sessionStorage.getItem("deviceIp"));

    const handleToggle1 = () => { setLocationModal(!locationModal); };

    useEffect(() => {
        setClick(false)
        setKeyword("")
        setSuggestions([])
    }, [history.location.pathname, activeSearch]);

    useEffect(() => setActiveSearch(false), [history.location.pathname]);

    const [click, setClick] = useState(false)
    const handleClick = () => {
        if (isValidLocation) {
            setClick(!click)
        } else {
            setLocationModal(true)
        }
    }
    // Function to handle mobile app header title
    const headerTitleFunction = () => {

        if (location.pathname.includes('/profile')) {
            return 'Profile'
        } else if (location.pathname.includes('/my_address')) {
            return 'My Address'
        } else if (location.pathname.includes('/edit_address')) {
            return 'Edit Address'
        } else if (location.pathname.includes('/add_address')) {
            return 'Add New Address'
        } else if (location.pathname.includes('/cart')) {
            return 'Cart'
        } else if (location.pathname.includes('/shipping_address')) {
            return 'Shipping Address'
        } else if (location.pathname.includes('/payment_method')) {
            return 'Payment Method'
        } else if (location.pathname.includes('/payment')) {
            return 'My Payment'
        } else if (location.pathname.includes('/add_card')) {
            return 'Add Card'
        } else if (location.pathname.includes('/promocode')) {
            return 'Promocode'
        } else if (location.pathname.includes('/success')) {
            return 'Success'
        } else if (location.pathname.includes('/live_tracking')) {
            return 'Track Driver'
        } else if (location.pathname.includes('/order_detail')) {
            return 'Order Detail'
        } else if (location.pathname.includes('/order_list')) {
            return 'Order List'
        } else if (location.pathname.includes('/notifications')) {
            return 'notifications'
        } else if (location.pathname.includes('/more_help')) {
            return 'more_help'
        } else if (location.pathname.includes('/Help-List')) {
            return 'Help-List'
        } else if (location.pathname.includes('/faq')) {
            return 'faq'
        } else if (location.pathname.includes('/return')) {
            return 'return'
        } else if (location.pathname.includes('/product_list')) {
            return 'product-list'
        } else if (location.pathname.includes('/product_detail')) {
            return 'product-detail'
        } else {
            return false
        }
    };

    // Fun to get cart data for loggedin and non loggedin
    useEffect(() => {
        if (isLoggedIn) {
            dispatch(getCartCount({ customerId: profileData?.id }))
            dispatch(getProfile())
        }
        else {
            if (JSON.parse(localStorage.getItem('cartTotal'))) {
                let length = JSON.parse(localStorage.getItem('cartTotal'))
                dispatch({
                    type: GET_CART_COUNT_LOCAL_SUCCESS, payload: length
                })
            }
            else {
                dispatch({
                    type: GET_CART_COUNT_LOCAL_ERROR, payload: 0
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    // Function for app header title
    // useEffect(() => {
    //     setheaderTitle(headerTitleFunction());

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [location.pathname])

    OutSideClick(dropDownRef, () => {
        if (showDropDown) { setshowDropDown(false); }
    });

    // Logout method
    const onLogout = () => {
        const fcmToken = JSON.parse(localStorage.getItem("FCMToken"));
        dispatch(doLogout({ fcmToken: fcmToken ? fcmToken : ' ' }))
        dispatch({ type: GET_CART_COUNT_SUCCESS, payload: 0 })
        dispatch({ type: SHLD_REDIRECT_TO_CART, payload: false })
        if (localStorage.getItem('cart')) {
            localStorage.removeItem('cart')
        }
        if (localStorage.getItem('cartTotal')) {
            localStorage.removeItem('cartTotal')
        }
    };

    const handleVisitorCall = () => {
        if (!sessionDeviceIp && deviceIp) {
            dispatch(
              visitorCall({
                ipAddress: deviceIp,
              })
            );
            sessionStorage.setItem("deviceIp", JSON.stringify(deviceIp));
          }
    }

    // For Location pop up detect location api
    const onDetectLocation = (manualPinCode, type) => {

        let tmpIp = JSON.parse(localStorage.getItem("deviceIp"));
        let tmpObj = { ipAddress: tmpIp, customerId: profileData?.id ? profileData?.id : '' }

        if (type === 'manual') {
            let manualData = { pincode: manualPinCode, ...tmpObj }
            setisLoading(true)
            dispatch(checkLocation(manualData))
                .then(res => {
                    setisLoading(false)
                    setLocationModal(false)
                    axios.defaults.headers.common["pincode"] = res.data?.pincode
                    localStorage.setItem('pincode', res.data?.pincode)
                    handleVisitorCall()
                    localStorage.setItem('validLocation', JSON.stringify(res.data?.pincode + ' ' + res.data?.area?.city))
                    setTimeout(() => {
                        dispatch({ type: VALID_LOCATION_SUCCESS, payload: JSON.stringify(res.data?.pincode + ' ' + res.data?.area?.city) })
                    }, 1000);
                    dispatch(productList({
                        page: 1,
                        limit: 1000,
                        customerId: '',
                        productType: ""
                    }))
                    history.push('/')

                    //  End Date -14-03-2022
                })
                .catch(err => {
                    setisLoading(false)
                    swal('', err.message, 'error')
                })
        }
        else {
            setisLoading(true)
            //success function
            navigator.geolocation.getCurrentPosition((pos) => {
                var crd = pos.coords

                pincodeFromLatLng(crd.latitude, crd.longitude)
                    .then(pincodeRes => {
                        dispatch(checkLocation({ pincode: pincodeRes, ...tmpObj }))
                            .then(res => {
                                setisLoading(false)
                                setLocationModal(false)
                                axios.defaults.headers.common["pincode"] = res.data?.pincode
                                localStorage.setItem('pincode', res.data?.pincode)
                                handleVisitorCall()
                                localStorage.setItem('validLocation', JSON.stringify(res.data?.pincode + ' ' + res.data?.area?.city))
                                setTimeout(() => {
                                    dispatch({ type: VALID_LOCATION_SUCCESS, payload: JSON.stringify(res.data?.pincode + ' ' + res.data?.area?.city) })
                                }, 1000);
                                dispatch(productList({
                                    page: 1,
                                    limit: 1000,
                                    customerId: '',
                                    productType: ""
                                }))
                            })
                            .catch(err => {
                                setisLoading(false)
                                swal(`Detected pincode : ${pincodeRes}`, err.message, 'error')
                            })
                    })
                    .catch(err => {
                        setisLoading(false)
                        console.log('err: ', err);
                    })

            }, (err) => {
                // error function
                setisLoading(false)
                if (err?.message) {
                    if (err?.code === 1)
                        swal('Location Permission Required', 'Please allow location permission !', 'error')
                    else if (err?.code === 2)
                        swal('Position unavailable !', 'Please try again or try by adding pincode manually !', 'error')
                    else if (err?.code === 3)
                        swal('Timed Out !', 'Please try again or try by adding pincode manually !', 'error')
                    else {
                        swal('', err?.message, 'error')
                    }
                }
                else {
                    alert('something went wrong')
                }
            }, {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            });
        }
    };

    return (
        <>
            {isLoading && <LoaderBox />}

            {/* location Modal */}
            <div className={` model-box location-model ${locationModal ? "model-open" : " "}`}>
                <div className="location-box">
                    <button className="model-close-btn" onClick={() => setLocationModal(false)}> <Close /> </button>
                    <h1>Where should we deliver the products?</h1>
                    <p>Please provide your Delivery Location to see products</p>
                    <form>
                        <div className="form-grp">
                            <NumberFormat
                                format={`#   #   #   #   #   #`}
                                onValueChange={(e) => {
                                    if (e.value.length === 6) { onDetectLocation(e.value, 'manual') }
                                }}
                                placeholder={`Type your pincode`}
                                className={`input-box`} />
                            {/* <input type="text" ref={inpRef} className="input-box" placeholder="Type your city or pincode" /> */}
                        </div>
                        <span>or</span>
                        <button type='button' onClick={() => onDetectLocation(false, 'auto')} className="links-btn" >Detect my location</button>
                    </form>
                </div>
            </div>
            <header className="mheader-bar">
                <div className="container-fluid">
                    <div className={click ? 'nav-menu active' : 'nav-menu'}>
                        <ul className='menu-nav-dropdown'>
                            <li className='cls-dropdown-nav'>
                                <a className='nav-item' href='/'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setActiveClass(!activeClass)
                                    }}>Home Care <IoIosArrowForward /></a>
                                <ul className={`cls-dropdown-sub ${activeClass ? "active" : ""}`}>
                                    <li>
                                        <Link to="/product_list/prefill/dish-wash-gel" className='nav-item'>
                                            Dishwash gel
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/product_list/prefill/glass-cleaner-" className='nav-item'>
                                            Glass cleaner
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/product_list/prefill/toilet-cleaner" className='nav-item'>
                                            Toilet cleaner
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/product_list/prefill/bathroom-cleaner" className='nav-item'>
                                            Bathroom cleaner
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/product_list/prefill/floor-cleaner" className='nav-item'>
                                            Floor cleaner
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a className='nav-item'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        history.push("/product_list/prefill/laundry-care")
                                    }}
                                >Laundry Care</a>
                            </li>
                            <li>
                                <a className='nav-item'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        history.push("/product_list/prefill/hand-wash")
                                    }}
                                >Hand Care</a>
                            </li>
                            <li>
                                <a className='nav-item'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        history.push("/product_list/refill/all")
                                    }}
                                >Refills</a>
                            </li>
                            <li>
                                <a className='nav-item'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        history.push("/about_clefill")
                                    }}
                                >Our Story</a>
                            </li>
                            <li>
                                <a className='nav-item'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        history.push("/contactus")
                                    }}
                                >Contact Us</a>
                            </li>
                            {isLoggedIn && <li>
                                <a className='nav-item'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        history.push("/Help-List")
                                    }}
                                >Help</a>
                            </li>}
                            {!isLoggedIn && <li>
                                <a className='nav-item'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        history.push("/login")
                                    }}
                                >Sign in/Create account</a>
                            </li>}
                        </ul>
                    </div>
                    <div className='row justify-content-between'>
                        <div className='col-6 col-md-6 col-lg-6'>
                            <div className='cls-mheader-left'>
                                <div className='hamburger' onClick={handleClick}>
                                    {click ? (<FaTimes size={20} style={{ color: '#333' }} />) : (<FaBars size={20} style={{ color: '#333' }} />)}
                                </div>
                                <div className="left-box text-center">
                                    <div onClick={(e) => { e.preventDefault(); history.push('/') }} className="logo-box">
                                        <Link to="/#"> <i> <Logo /> </i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-6 col-md-6 col-lg-6 pl-0'>
                            <div className="right-box">
                                {history?.location?.pathname === "/about_clefill" || history?.location?.pathname === "/" &&
                                    <i className="search-btn" onClick={() => setActiveSearch(true)}> <CgSearch /> </i>
                                }
                                <i className="users" onClick={() => { setshowDropDown(!showDropDown) }}> <BiUser /> </i>
                                <div className={`drop-down ${showDropDown ? 'active' : ''}`} ref={dropDownRef}>
                                    <ul className="drop-downlist">
                                        {isLoggedIn && <li className="bottom-line"> <NavLink to="/" onClick={(e) => { e.preventDefault() }} className="user" >Hello {profileData.firstName} {profileData.lastName}</NavLink> </li>}
                                        <li> <NavLink to="/" onClick={(e) => { e.preventDefault(); handleToggle1() }} className="loction-icon"> {authData?.isValidLocation ? removeQuotsFun(authData?.validLocation) : 'No Location '}&nbsp;{` Change`} </NavLink> </li>
                                        {!isLoggedIn &&
                                            <>
                                                <li>
                                                    <NavLink to="/"
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            if (!isValidLocation) {
                                                                setLocationModal(true)
                                                            }
                                                            else {
                                                                history.push('/login')
                                                            }
                                                        }}>
                                                        Sign in
                                                    </NavLink>
                                                </li>
                                                {/* <li>
                                                <NavLink to="/signup"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        if (!isValidLocation) {
                                                            setLocationModal(true)
                                                        }
                                                        else {
                                                            history.push('/login')
                                                        }
                                                    }}>
                                                    Register
                                                </NavLink>
                                            </li> */}
                                            </>
                                        }
                                        {isLoggedIn &&
                                            <>
                                                <li> <NavLink to="/profile" onClick={(e) => { setshowDropDown(false) }}> My Profile </NavLink> </li>
                                                {/* <li> <NavLink to="/payment" onClick={(e) => { setshowDropDown(false) }}> My Payment </NavLink> </li> */}
                                                <li> <NavLink to="/promocode" onClick={(e) => { setshowDropDown(false) }}> My Promo Code </NavLink> </li>
                                                <li className="bottom-line"> <NavLink to="/referralcode" onClick={(e) => { setshowDropDown(false) }}>Refer & Save!</NavLink> </li>
                                                <li> <NavLink to="/order_list" onClick={(e) => { setshowDropDown(false) }}> My Orders </NavLink> </li>
                                                <li> <NavLink to="/Help-List" onClick={(e) => { setshowDropDown(false) }}> Help Center </NavLink> </li>
                                            </>
                                        }
                                        {isLoggedIn && <li> <NavLink to="/" onClick={() => onLogout()}> Log out </NavLink> </li>}
                                    </ul>
                                </div>
                                {isLoggedIn && <i onClick={(e) => { e.preventDefault(); history.push('/notifications') }} className={`${notificationCount > 0 ? 'green-dots' : ''}`}> <MdOutlineNotificationsNone /> </i>}
                                <i onClick={(e) => {
                                    e.preventDefault();
                                    if (isValidLocation)
                                        history.push('/cart')
                                    else
                                        setLocationModal(true)
                                }} className="cart"> <BsCart2 />  {isLoggedIn ? (cartCount > 0 && <span> {cartCount} </span>) : (cartCountLocal > 0 && <span> {cartCountLocal} </span>)} {/*<b>5000</b>*/} </i>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className='cls-m-search-header'>
                {(history?.location?.pathname === '/' || history?.location?.pathname === "/about_clefill") && activeSearch &&
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
                            <div className='search-var-box'>
                                <input type='text' className='floating__input' value={keyword}
                                    onChange={e => {
                                        e.preventDefault()
                                        if (isValidLocation) {
                                            setKeyword(e.target.value)
                                            const value = e.target.value
                                            if (value?.length > 0) {
                                                const filteredNames = allProductList.filter((item) => item?.productName.trim().toLowerCase().includes(value.trim().toLowerCase())).splice(0, 5)
                                                setSuggestions(filteredNames);
                                            } else {
                                                setSuggestions([])
                                                setActiveSearch(false)
                                            }
                                        }
                                        else {
                                            setLocationModal(true)
                                        }
                                    }}
                                    // onBlur={() => {
                                    // setKeyword()
                                    // setSuggestions([])
                                    // }}
                                    placeholder='search product'
                                />
                                <button type='button' className='search-btn' onClick={() => setActiveSearch(false)}><RxCross2 /></button>
                                <ul className='search-reac'>
                                    {keyword && suggestions.map((item, index) => (
                                        <li key={index} onClick={() => { history.push(`/product_detail/${item?.productcategory.category.toLowerCase()}/${item?.productName.replace(/\s+/g, '-')}`) }}>{item?.productName}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </div>

        </>
    );
}

export default Header;