import * as ACTION_TYPES from '../../constants/types';

const initialState = {
  // Product Details
  productList: [],
  productListLimitMobile: 4,
  productListLimitWeb: 9,
  pType: [],
  pCategory: [],
  productInfo: {},

  // Cart Details
  cart: [],
  cartDetails: {
    discount: 0,
    orderTotal: 0,
    subTotal: 0,
  },
  cartCount: 0,
  cartCountLocal: 0,
  cartSubTotal: 0,
  cartDiscount: 0,
  cartTotal: 0,
  // To manage redirection to cart in mobile 
  shouldRedirectToCart: false,

  message: '',
  error: null
}

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.PROD_LIST_SUCCESS:
      return {
        ...state,
        productList: action.payload.data.list ? action.payload.data?.list : [],
        message: action.payload.message,
        isLoggedIn: true,
        error: null
      }
    case ACTION_TYPES.PROD_LIST_ERROR:
      return {
        ...state,
        productList: [],
      }
    case ACTION_TYPES.PROD_DETAIL_SUCCESS:
      return {
        ...state,
        productInfo: action.payload.data,
        message: action.payload.message,
        isLoggedIn: true,
        error: null
      }
    case ACTION_TYPES.PROD_DETAIL_ERROR:
      return {
        ...state,
        productInfo: {},
      }
    case ACTION_TYPES.GET_CART_SUCCESS:
      return {
        ...state,
        cart: action.payload.data?.list ? action.payload.data.list : [],
        cartDetails: action.payload.data.extra ? action.payload.data.extra : { discount: 0, orderTotal: 0, subTotal: 0, },
        error: null
      }
    case ACTION_TYPES.GET_CART_ERROR:
      return {
        ...state,
        cart: [],
        cartDetails: { discount: 0, orderTotal: 0, subTotal: 0, }
      }
    case ACTION_TYPES.GET_CART_COUNT_SUCCESS:
      return {
        ...state,
        cartCount: action.payload.data?.count,
        error: null
      }
    case ACTION_TYPES.GET_CART_COUNT_ERROR:
      return {
        ...state,
        cartCount: 0,
      }
    case ACTION_TYPES.SHLD_REDIRECT_TO_CART:
      return {
        ...state,
        shouldRedirectToCart: action.payload,
      }
    case ACTION_TYPES.PROD_TYPE_SUCCESS:
      return {
        ...state,
        pType: action.payload.data.list ? action.payload.data?.list : [],
        error: null
      }
    case ACTION_TYPES.PROD_TYPE_ERROR:
      return {
        ...state,
        pType: [],
      }
    case ACTION_TYPES.PROD_CATEGORY_SUCCESS:
      return {
        ...state,
        pCategory: action.payload.data.list ? action.payload.data?.list : [],
        error: null
      }
    case ACTION_TYPES.PROD_CATEGORY_ERROR:
      return {
        ...state,
        pCategory: [],
      }
    case ACTION_TYPES.GET_CART_COUNT_LOCAL_SUCCESS:
      return {
        ...state,
        cartCountLocal: action.payload,
        error: null
      }
    case ACTION_TYPES.GET_CART_COUNT_LOCAL_ERROR:
      return {
        ...state,
        cartCountLocal: 0,
      }
    case ACTION_TYPES.ADD_CART_DISCOUNT_SUCCESS:
      return {
        ...state,
        cartDiscount: action.payload
      }
    case ACTION_TYPES.ADD_CART_TOTAL_SUCCESS:
      return {
        ...state,
        cartTotal: action.payload
      }
    case ACTION_TYPES.PRODUCT_LIST_LIMIT_MOBILE:
      return {
        ...state,
        productListLimitMobile: action.payload
      }
    case ACTION_TYPES.PRODUCT_LIST_LIMIT_WEB:
      return {
        ...state,
        productListLimitWeb: action.payload
      }
    case ACTION_TYPES.ADD_CART_SUB_TOAL_SUCCESS:
      return {
        ...state,
        cartSubTotal: action.payload
      }
    case ACTION_TYPES.LOGOUT:
    case ACTION_TYPES.AUTHENTICATE_FAILED:
      return {
        ...state,
        cart: {},
        cartCount: 0,
      }
    default:
      return state;
  }
}
