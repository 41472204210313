import React from 'react';

const Instagram = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="url(#paint0_linear_712_788)" />
                <path d="M14.5466 5.33203H9.45327C7.18127 5.33203 5.33594 7.17736 5.33594 9.44936V14.5427C5.33594 16.8147 7.18127 18.66 9.45327 18.66H14.5466C16.8186 18.66 18.6639 16.8147 18.6639 14.5427V9.44936C18.6639 7.17736 16.8186 5.33203 14.5466 5.33203ZM17.1759 14.548C17.1759 15.9987 15.9973 17.1827 14.5413 17.1827H9.44794C7.99727 17.1827 6.81327 16.004 6.81327 14.548V9.4547C6.81327 8.00403 7.99194 6.82003 9.44794 6.82003H14.5413C15.9919 6.82003 17.1759 7.9987 17.1759 9.4547V14.548Z" fill="white" />
                <path d="M11.9978 8.58984C10.1205 8.58984 8.58984 10.1205 8.58984 11.9978C8.58984 13.8752 10.1205 15.4058 11.9978 15.4058C13.8752 15.4058 15.4058 13.8752 15.4058 11.9978C15.4058 10.1205 13.8752 8.58984 11.9978 8.58984V8.58984ZM11.9978 14.0672C10.8565 14.0672 9.92851 13.1392 9.92851 11.9978C9.92851 10.8565 10.8565 9.92851 11.9978 9.92851C13.1392 9.92851 14.0672 10.8565 14.0672 11.9978C14.0672 13.1392 13.1392 14.0672 11.9978 14.0672Z" fill="white" />
                <path d="M15.6649 8.96283C15.9789 8.91193 16.1922 8.61614 16.1413 8.30216C16.0904 7.98818 15.7946 7.77491 15.4806 7.82581C15.1666 7.87671 14.9534 8.1725 15.0043 8.48648C15.0552 8.80046 15.351 9.01373 15.6649 8.96283Z" fill="white" />
                <defs>
                    <linearGradient id="paint0_linear_712_788" x1="2.86288" y1="21.1371" x2="19.9162" y2="4.08379" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FEE411" />
                        <stop offset="0.0518459" stopColor="#FEDB16" />
                        <stop offset="0.1381" stopColor="#FEC125" />
                        <stop offset="0.2481" stopColor="#FE983D" />
                        <stop offset="0.3762" stopColor="#FE5F5E" />
                        <stop offset="0.5" stopColor="#FE2181" />
                        <stop offset="1" stopColor="#9000DC" />
                    </linearGradient>
                </defs>
            </svg>
        </>
    )
}
export default Instagram;