// /**
//  * Axios config  setup
//  * Set interceptor for global api response error handling
//  * Set access token in headers
//  */
import axios from "axios";
import { LOGOUT } from "../constants/types";
import store from "../redux/store";

(function (axios) {
  const token = JSON.parse(localStorage.getItem("authTokenWebsite"));
  if (token) {
    axios.defaults.headers.common["authorization"] = token;
  } else {
    delete axios.defaults.headers.common["authorization"];
  }

  const pincode = JSON.parse(localStorage.getItem("validLocation"));
  if (pincode) {
    let pincodeArray = pincode?.split(" ");
    if (pincodeArray?.length) {
      axios.defaults.headers.common["pincode"] = pincodeArray[0];
    } else {
      delete axios.defaults.headers.common["pincode"];
    }
  } else {
    delete axios.defaults.headers.common["pincode"];
  }

  axios.interceptors.response.use(null, (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 417) {
        store.dispatch({
          type: LOGOUT,
        });
        return Promise.reject(error);
      } else if (error.response.status === 422) {
        localStorage.removeItem("validLocation");
        setTimeout(() => {
          window.location.reload();
        }, 200);
        return Promise.reject(error);
      } else return Promise.reject(error);
    } else if (error.request) {
      let err = {
        response: {
          data: {
            message: "Something went wrong,Please try again later!!!",
          },
        },
      };
      return Promise.reject(err);
    }
  });
})(axios);
