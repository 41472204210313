import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import store, { persistor } from "../redux/store";
import Layout from "../mobile/components/layout";
import WebLayout from "../web/components/layout";
import Registere from "../mobile/pages/auth/Login";
import Otp from "../mobile/pages/auth/otp";
import Account from "../mobile/pages/auth/Register";
import ScrollToTop from "../mobile/components/common/scroll";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../utils/AxiosConfig";
import checkAuth from "../Authentication/CheckAuth";
import { authenticate } from "../redux/actions/authAction";
import { AUTHENTICATE_FAILED } from "../constants/types";
import { isMobile, isTablet } from "react-device-detect";

import "react-step-progress-bar/styles.css";
import PaymentLink from "../web/pages/PaymentLink";

// Notification Firebase setup
import firebase from "firebase/app";
import { onMessageListener } from "../firebase";
import { getNotificationCount } from "../redux/actions/utilsAction";
import { getIpClient } from "../utils";
import OnlinePaymentSuccess from "../web/pages/OnlinePaymentSuccess";
import { ErrorBoundary } from "../Error";
import { visitorCall } from "../redux/actions/productAction";

// For Detect mobile and tablet
if (!(isMobile || isTablet)) {
  import("../../src/assets/scss/webStyle.scss");
}

//alert css and js
const { PUBLIC_URL } = process.env;
const token = JSON.parse(localStorage.getItem("authTokenWebsite"));
const loggedInUser = JSON.parse(localStorage.getItem("userWebsite"));
const deviceIp = JSON.parse(localStorage.getItem("deviceIp"));
const sessionDeviceIp = JSON.parse(sessionStorage.getItem("deviceIp"));
const pincode = JSON.parse(localStorage.getItem("validLocation"));

if (token && loggedInUser) {
  store.dispatch(authenticate(loggedInUser));
} else {
  store.dispatch({ type: AUTHENTICATE_FAILED });
}

if (!deviceIp) {
  getIpClient()
    .then((response) => response.json())
    .then((data) => {
      let tmp = data?.ip ? data.ip : "";
      localStorage.setItem("deviceIp", JSON.stringify(tmp));
    })
    .catch((err) => {
      console.log("err: ", err);
    });
}
toast.configure();

const AppContainer = () => {

  useEffect(() => {
    if (!sessionDeviceIp && deviceIp && pincode) {
      store.dispatch(
        visitorCall({
          ipAddress: deviceIp,
        })
      );
      sessionStorage.setItem("deviceIp", JSON.stringify(deviceIp));
    }
  }, [sessionDeviceIp, deviceIp, pincode]);

  useEffect(() => {
    let div = document.getElementById("root");
    if (navigator.userAgent.indexOf("Mac OS X") !== -1)
      div.classList.add("mac");
    else div.classList.remove("remove");
  }, []);

  if (firebase.messaging.isSupported()) {
    onMessageListener()
      .then((payload) => {
        store.dispatch(getNotificationCount());
      })
      .catch((err) => console.log(err));
  }

  return (
    <Provider store={store} loading={null}>
      <PersistGate persistor={persistor} loading={null}>
        <Suspense fallback={null}>
          <BrowserRouter basename={PUBLIC_URL}>
            <ErrorBoundary>
              <ScrollToTop>
                {!(isMobile || isTablet) ? (
                  <Switch>
                    {/* webroute */}
                    <Route
                      path="/online-payment-link/:orderId/:token"
                      component={PaymentLink}
                    />
                    <Route
                      path="/online-payment-link-success/:payment/:id"
                      component={OnlinePaymentSuccess}
                    />
                    <Route path="/" component={checkAuth(WebLayout)} />
                  </Switch>
                ) : (
                  <Switch>
                    {/* app routes */}
                    <Route
                      path="/online-payment-link/:orderId/:token"
                      component={PaymentLink}
                    />
                    <Route
                      path="/online-payment-link-success/:payment/:id"
                      component={OnlinePaymentSuccess}
                    />
                    <Route path="/login" exact={true} component={Registere} />
                    <Route
                      path="/login/:type"
                      exact={true}
                      component={Registere}
                    />
                    <Route path="/otp/:path" component={Otp} />
                    <Route path="/signup" exact={true} component={Account} />
                    <Route
                      path="/signup/:type"
                      exact={true}
                      component={Account}
                    />
                    <Route path="/" component={checkAuth(Layout)} />
                  </Switch>
                )}
              </ScrollToTop>
            </ErrorBoundary>
          </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>
  );
};

export default AppContainer;
