import React from 'react';

const Products_img = () => {
	return (
		<>
			<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="350px"
				height="229px" viewBox="0 0 400 229" >
				<g id="Background">
					<path fillRule="evenodd" clipRule="evenodd" fill="#444444" d="M157.09,24.61l-0.55,16.1c-0.91,0.23-2.05,1.09-2.74,2.22
					c-0.21,3.6-0.42,6.34-0.64,9.94c-3.33,2.39-6.67,7.24-10.01,14.41c-4.76,10.57-6.93,21.15-7.88,31.73l0.32,110.44l-4.84-52.39
					l-9.96-104.84c-0.44-4.97-1.68-9.73-5.3-13.9l-5.6-5.6v-2.09l-0.82-11.8c-1.42-2.9-4-4.86-7.91-5.76c0.29-4.17-1.13-6.86-5.22-7.24
					L95.34,0h-4.36l-0.64,5.9c-4.24,0.72-5.1,3.9-4.96,7.81l-3.88-0.15c-2.64,1.3-4.43,4.28-5.08,9.56l-0.44,5.68
					c0.27,1,0.8,1.74,1.56,2.24l-6.86,7.69c-2.04,3.25-2.6,7.16-2.84,11.21l-2.31,34.51l-7.13,91.33l-0.02-45.36
					c-0.94-10.7-8.22-15.8-19.31-17.54v-2.03l3.05-0.11c0.82-0.13,1.65-0.27,0.5-1.01l0.28-0.5l-0.02-13.48l-0.31-0.33
					c0.26-1.29-0.88-1.33-2.13-1.23l-4.39-0.03l0.03-0.76l0.45-0.11l-1.07-1.49l0.07-0.28l0.74-0.05l-0.15-1.91l-2.24-0.17l-0.08-5.82
					c0.35-3.81,13.16-2.33,15.83-1.97l2.26-2.31c-8.19-2.02-14.23-1.61-22.31-0.96c-2.18-0.15-4.34-0.6-6.44-1.4l-0.55,2.16
					c1.4,0.3,2.24,0.61,2.21,2.41l0.15,7.9c-0.65,0.07-1.22,0.05-1.94,0.05c-0.46,0.51-0.49,1.3-0.25,2c0.27,0.04,0.56-0.01,0.83,0.02
					c-0.35,0.61-0.7,1.31-1.05,1.92l0.56,0.05l-0.04,0.79l-5.6,0.19c-0.76,0.1-1.22,0.48-1.27,1.25l0.01,13.49l0.32,0.35l-0.39,0.19
					c-0.24,0.55-0.11,0.84,0.28,0.94l3.04,0.18l0.03,1.95C8.21,114.47,0.22,119.64,0,133v67.3l1.68-0.01
					c-0.04-22.43-0.07-44.86,0.04-67.28c0.06-11.97,6.94-16.86,18.01-18.54l1.49-0.22l-0.03-1.5l-0.02-1.95l-0.03-1.6l-1.59-0.09
					l-1.12-0.07l-0.51-0.55l-0.01-12.36l5.21-0.18l1.57-0.06l0.08-1.57l0.05-0.78l0.04-0.82l0.22-0.39l0.73-1.29l0.89-0.09l-0.03-1.58
					l-0.15-7.87c0.01-0.67-0.08-1.32-0.33-1.9c1.08,0.22,2.17,0.37,3.26,0.45l0.13,0.01l0.14-0.01c2.53-0.21,5.08-0.41,7.62-0.49
					c-2.57,0.4-5.03,1.4-5.25,3.85l-0.01,0.09v0.09l0.08,5.83l0.03,1.57l1.56,0.12l0.11,0.01l-0.07,0.27l-0.19,0.77l0.47,0.65
					l0.29,0.41v0.12l-0.03,0.75l-0.06,1.78l1.77,0.01l4.39,0.03h0.08l0.07-0.01l0.14-0.01l-0.01,0.05l0.45,0.49l0.02,12.35l-0.06,0.12
					l-0.09,0.16l-2.02,0.07l-1.66,0.06v1.66v2.03v1.48l1.46,0.22c9.53,1.5,16.91,5.57,17.85,15.92l0.04,67.17l-0.04,0.49h0.04
					l1.71-0.13h0.01v-0.07c2.98-37.79,5.96-75.58,8.82-113.38c0.87-11.5,1.57-23.02,2.32-34.53c0.22-3.47,0.65-7.25,2.5-10.28
					l6.77-7.59l1.33-1.49l-1.68-1.09c-0.38-0.25-0.61-0.59-0.76-1l0.42-5.31c0.34-2.75,1.18-6.45,3.76-7.99l3.41,0.13l1.85,0.07
					l-0.06-1.86c-0.05-1.43,0-3.41,0.88-4.63c0.64-0.86,1.62-1.24,2.65-1.41l1.28-0.22l0.14-1.29l0.47-4.37h1.27L94.23,6l0.14,1.41
					l1.41,0.13c3.27,0.31,3.87,2.42,3.67,5.41l-0.1,1.47l1.43,0.33c2.82,0.65,5.22,1.98,6.6,4.55l0.79,11.39v2.03v0.71l0.51,0.51
					l5.55,5.56c3.24,3.74,4.39,8.06,4.85,12.88c3.29,34.95,6.68,69.89,9.96,104.84c2.23,23.71,4.38,47.44,6.57,71.15h0.03l1.66-0.31
					l0.06-0.01l-0.37-128.97c0.98-10.83,3.19-21.15,7.73-31.09c2.02-4.44,5.38-10.81,9.44-13.72l0.67-0.48l0.05-0.82
					c0.19-3.15,0.42-6.3,0.61-9.46c0.38-0.48,0.97-1,1.48-1.13l1.25-0.32l0.04-1.29l0.52-15.19c4.78-2.69,14.51-2.58,19.19,0.24
					l0.43,14.67l0.02,1.03l0.93,0.46c1.03,0.51,1.87,1.2,2.09,2.35l0.27,8.71l0.03,0.88l0.72,0.49c3.93,2.67,6.97,7.57,9.21,11.67
					c2.85,5.46,4.9,11.12,6.31,17.11c1.26,5.39,1.97,10.84,2.62,16.32c-0.1,9.73-0.17,19.45-0.25,29.17c-0.22,1.34-0.35,2.77-0.38,4.32
					v28.88v0.13l-0.03,1.91h0.03l1.65,0.27h0.02l0.05-2.16l0.33-33.17c1.51-8.99,7.99-12.92,17.68-14.39l1.49-0.22l-0.02-1.5
					l-0.03-1.95l-0.03-1.6l-1.59-0.09l-1.12-0.07l-0.51-0.55l-0.01-12.36l5.21-0.18l1.57-0.06l0.09-1.57l0.04-0.78l0.04-0.82l0.22-0.39
					l0.73-1.29l0.89-0.09l-0.03-1.58l-0.15-7.87c0.01-0.67-0.08-1.32-0.32-1.9c1.07,0.22,2.16,0.37,3.25,0.45l0.13,0.01l0.14-0.01
					c2.53-0.21,5.08-0.41,7.62-0.49c-2.57,0.4-5.03,1.4-5.25,3.85l-0.01,0.09v0.09l0.08,5.83l0.03,1.57l1.56,0.12l0.11,0.01l-0.06,0.27
					l-0.2,0.77l0.47,0.65l0.29,0.41v0.12l-0.03,0.75l-0.06,1.78l1.77,0.01l4.39,0.03h0.08l0.07-0.01l0.14-0.01l-0.01,0.05l0.45,0.49
					l0.03,12.35l-0.07,0.12l-0.09,0.16l-2.02,0.07l-1.66,0.06v1.66v2.03v1.48l1.46,0.22c9.53,1.5,16.91,5.57,17.85,15.92l0.05,98.35
					h0.02l1.71,0.15h0.01c0.34-3.82,0.69-7.63,0.99-11.45c3.49-44.32,7.01-88.64,10.37-132.97c0.87-11.5,1.57-23.02,2.32-34.53
					c0.22-3.47,0.65-7.25,2.5-10.28l6.76-7.59l1.34-1.49l-1.68-1.09c-0.38-0.25-0.62-0.59-0.77-1l0.42-5.31
					c0.34-2.75,1.19-6.45,3.77-7.99l3.41,0.13l1.85,0.07l-0.07-1.86c-0.05-1.43,0.01-3.41,0.89-4.63c0.63-0.86,1.62-1.24,2.65-1.41
					l1.28-0.22l0.14-1.29l0.47-4.37h1.27L296.77,6l0.14,1.41l1.4,0.13c3.28,0.31,3.88,2.42,3.67,5.41l-0.1,1.47l1.43,0.33
					c2.83,0.65,5.23,1.98,6.61,4.55l0.79,11.39v2.03v0.71l0.5,0.51l5.56,5.56c3.23,3.74,4.39,8.06,4.84,12.88
					c3.29,34.95,6.68,69.89,9.96,104.84l1.47,15.69l0.03-0.01l1.66-0.24l0.06-0.01l-0.12-42.22c0.97-10.83,3.19-21.16,7.73-31.09
					c2.02-4.44,5.38-10.81,9.44-13.72l0.67-0.48l0.05-0.82c0.19-3.16,0.42-6.31,0.61-9.46c0.38-0.48,0.97-1.01,1.48-1.14l1.25-0.32
					l0.04-1.29l0.52-15.19c4.78-2.68,14.51-2.58,19.19,0.24l0.42,14.67l0.03,1.04l0.93,0.46c1.03,0.5,1.87,1.2,2.09,2.35l0.27,8.7
					l0.02,0.88l0.73,0.5c3.92,2.66,6.97,7.57,9.21,11.66c2.85,5.47,4.9,11.12,6.31,17.12c1.26,5.38,1.97,10.83,2.62,16.32
					c-0.21,20.83-0.29,41.66-0.63,62.49l-0.02,0.99h1.72l0.02-0.96l0.63-62.61c-1.3-10.97-2.78-21.98-9.13-34.16
					c-2.85-5.21-5.96-9.69-9.76-12.28l-0.27-8.83c-0.27-1.67-1.35-2.88-3.05-3.72l-0.44-15.55c-5.06-3.77-17.18-4.06-22.58-0.28
					l-0.55,16.1c-0.91,0.24-2.05,1.1-2.74,2.23c-0.21,3.59-0.42,6.34-0.64,9.94c-3.33,2.39-6.67,7.23-10.01,14.4
					c-4.76,10.58-6.93,21.16-7.88,31.73l0.07,23.9l-9.69-102.03c-0.44-4.97-1.68-9.73-5.3-13.9l-5.6-5.6v-2.09l-0.82-11.8
					c-1.42-2.9-4-4.86-7.91-5.76c0.29-4.17-1.13-6.86-5.22-7.24L297.88,0h-4.37l-0.63,5.9c-4.24,0.72-5.11,3.9-4.96,7.81l-3.88-0.15
					c-2.64,1.3-4.43,4.28-5.08,9.56l-0.45,5.68c0.27,1,0.8,1.74,1.57,2.24l-6.87,7.69c-2.03,3.25-2.59,7.16-2.83,11.21l-2.31,34.51
					l-9.68,124l-0.04-78.03c-0.94-10.7-8.22-15.8-19.31-17.54v-2.03l3.05-0.11c0.82-0.13,1.65-0.27,0.51-1.01l0.28-0.5l-0.03-13.48
					l-0.31-0.33c0.26-1.29-0.88-1.33-2.13-1.23l-4.39-0.03l0.03-0.76l0.45-0.11l-1.07-1.49l0.07-0.28l0.74-0.05l-0.15-1.91l-2.24-0.17
					l-0.08-5.82c0.35-3.81,13.16-2.33,15.83-1.97l2.26-2.31c-8.19-2.02-14.23-1.61-22.31-0.96c-2.18-0.15-4.34-0.6-6.44-1.4l-0.55,2.16
					c1.4,0.3,2.24,0.61,2.21,2.41l0.15,7.9c-0.65,0.07-1.22,0.05-1.94,0.05c-0.46,0.51-0.49,1.3-0.25,2c0.28,0.04,0.56-0.01,0.83,0.02
					c-0.35,0.61-0.7,1.31-1.05,1.92l0.56,0.05l-0.04,0.79l-5.6,0.19c-0.76,0.1-1.22,0.48-1.27,1.25l0.01,13.49l0.32,0.35l-0.39,0.19
					c-0.24,0.55-0.11,0.84,0.28,0.94l3.04,0.18l0.03,1.95c-7.95,1.2-14.26,4.12-17.37,10.42l0.24-23.77
					c-1.29-10.97-2.78-21.98-9.13-34.15c-2.85-5.22-5.96-9.7-9.76-12.28l-0.27-8.83c-0.27-1.68-1.35-2.88-3.05-3.72l-0.44-15.55
		C174.61,21.11,162.49,20.82,157.09,24.61z"/>
				</g>
				<g id="Layer_1">
				</g>
			</svg>

		</>
	)
}
export default Products_img;