import {
    API_ADD_USER_ADDRESS,
    API_CHECK_LOCATION,
    API_CREATE_ACC,
    API_DELETE_USER_ADDRESS,
    API_GET_ACITVE_AREA_LIST,
    API_GET_ALL_ADDRESS,
    API_GET_CMS_API,
    API_GET_USER_PROFILE,
    API_LOCATION_FRM_PIN,
    API_LOGIN,
    API_LOGOUT,
    API_SELECT_USER_ADDRESS_DEFAULT,
    API_SEND_OTP,
    API_UPDATE_USER_ADDRESS,
    API_UPDATE_USER_PROFILE,
    API_VERIFY_OTP
} from "../../constants/api";
import {
    API, AUTHENTICATE_FAILED, AUTHENTICATE_USER, FETCH_ADDRESS_ERROR, FETCH_ADDRESS_SUCCESS, FETCH_LOCATION_FRM_PINCODE_SUCCESS, FETCH_PROFILE_ERROR, FETCH_PROFILE_SUCCESS, GET_ACTIVE_AREA_LIST_F, GET_ACTIVE_AREA_LIST_S, GET_CMS_ERROR, GET_CMS_SUCCESS, LOADER_STATUS_CHANGE, LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT, VALID_LOCATION_ERROR, VALID_LOCATION_SUCCESS,
} from "../../constants/types";

export const onLoaderChange = status => dispatch => {
    if (status) {
        return dispatch({ type: LOADER_STATUS_CHANGE, payload: true })
    } else {
        return dispatch({ type: LOADER_STATUS_CHANGE, payload: false })
    }
}
//On signin
export const onSignIn = (data) => ({
    type: API,
    payload: {
        url: API_LOGIN,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: LOGIN_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: LOGIN_ERROR,
            payload: data
        })
    }
})

//Create Account
export const createAcc = (data) => ({
    type: API,
    payload: {
        url: API_CREATE_ACC,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: LOGIN_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: LOGIN_ERROR,
            payload: data
        })
    }
})

// Send Otp
export const sendOTP = (data) => ({
    type: API,
    payload: {
        url: API_SEND_OTP,
        method: 'POST',
        data: data,
    }
})

// verify Otp
export const verifyOTP = (data) => ({
    type: API,
    payload: {
        url: API_VERIFY_OTP,
        method: 'POST',
        data: data,
    }
})

//To reAuthenticate if the cookie is not expired
export const authenticate = user => dispatch => {
    if (user) {
        return dispatch({ type: AUTHENTICATE_USER, payload: user })
    } else {
        return dispatch({ type: AUTHENTICATE_FAILED })
    }
}
// To logout
export const doLogout = (data) => ({
    type: API,
    payload: {
        url: API_LOGOUT,
        method: 'POST',
        hideLoader: true,
        data: data,
        success: (data) => ({
            type: LOGOUT,
        }),
        error: (data) => ({
            payload: data
        })
    }
})

// To check location is valid or not
export const checkLocation = (data) => ({
    type: API,
    payload: {
        url: API_CHECK_LOCATION,
        method: 'POST',
        hideLoader: true,
        data: data,
        success: (data) => ({
            type: VALID_LOCATION_SUCCESS,
            payload: data?.data?.pincode
        }),
        error: (data) => ({
            type: VALID_LOCATION_ERROR,
            payload: data
        })
    }
})

// To convert pincode into location name
export const getNameFrmPincode = (data) => ({
    type: API,
    payload: {
        url: API_LOCATION_FRM_PIN + data,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: FETCH_LOCATION_FRM_PINCODE_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: FETCH_LOCATION_FRM_PINCODE_SUCCESS,
            payload: data
        })
    }
})

//Get user profile 
export const getProfile = (data) => ({
    type: API,
    payload: {
        url: API_GET_USER_PROFILE,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: FETCH_PROFILE_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: FETCH_PROFILE_ERROR,
            payload: data
        })
    }
})

//Update user profile 
export const updateProfile = (data) => ({
    type: API,
    payload: {
        url: API_UPDATE_USER_PROFILE,
        method: 'PUT',
        data: data,
    }
})

//Get user profile 
export const getAllAddress = (data) => ({
    type: API,
    payload: {
        url: API_GET_ALL_ADDRESS,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: FETCH_ADDRESS_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: FETCH_ADDRESS_ERROR,
            payload: data
        })
    }
})

//Add user Addresses
export const addAddress = (data) => ({
    type: API,
    payload: {
        url: API_ADD_USER_ADDRESS,
        method: 'POST',
        data: data,
    }
})

//Update user Addresses
export const updateAddress = (data) => ({
    type: API,
    payload: {
        url: API_UPDATE_USER_ADDRESS,
        method: 'PUT',
        data: data,
    }
})

//delete user Addresses
export const deleteAddress = (data) => ({
    type: API,
    payload: {
        url: API_DELETE_USER_ADDRESS,
        method: 'DELETE',
        data: data,
    }
})

//Select address as default
export const selectAddressDefault = (data) => ({
    type: API,
    payload: {
        url: API_SELECT_USER_ADDRESS_DEFAULT,
        method: 'POST',
        data: data,
    }
})

//Get CMS data 
export const getCMSData = (data) => ({
    type: API,
    payload: {
        url: API_GET_CMS_API,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: GET_CMS_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_CMS_ERROR,
            payload: data
        })
    }
})

// Get active pincode
export const getActiveServicableAreas = (data) => ({
    type: API,
    payload: {
        url: API_GET_ACITVE_AREA_LIST,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_ACTIVE_AREA_LIST_S,
            payload: data
        }),
        error: (data) => ({
            type: GET_ACTIVE_AREA_LIST_F,
            payload: data
        })
    }
})