import React from 'react';

const Facebook = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_712_799)">
                    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#334C8C" />
                    <path d="M15.0403 10.8647L14.8414 12.5353C14.8328 12.6129 14.7958 12.6845 14.7375 12.7364C14.6791 12.7883 14.6037 12.8168 14.5256 12.8163H12.7888V17.7742C12.7892 17.8334 12.7662 17.8904 12.7248 17.9327C12.6834 17.975 12.6269 17.9992 12.5677 18H10.7977C10.7683 17.9996 10.7392 17.9934 10.7121 17.9817C10.6851 17.9701 10.6606 17.9532 10.6401 17.9321C10.6196 17.911 10.6034 17.886 10.5925 17.8587C10.5816 17.8313 10.5762 17.8021 10.5767 17.7726L10.5861 12.8163H9.27087C9.18712 12.8163 9.10679 12.783 9.04757 12.7238C8.98835 12.6646 8.95508 12.5843 8.95508 12.5005V10.83C8.95508 10.7462 8.98835 10.6659 9.04757 10.6067C9.10679 10.5475 9.18712 10.5142 9.27087 10.5142H10.5782V8.89421C10.5782 7.02 11.693 6 13.3256 6H14.663C14.7467 6 14.827 6.03327 14.8863 6.09249C14.9455 6.15171 14.9788 6.23204 14.9788 6.31579V7.72263C14.9788 7.80638 14.9455 7.88671 14.8863 7.94593C14.827 8.00515 14.7467 8.03842 14.663 8.03842H13.8435C12.9561 8.05263 12.7888 8.47737 12.7888 9.1121V10.5095H14.7324C14.7768 10.5102 14.8205 10.5203 14.8607 10.5391C14.9008 10.5579 14.9366 10.5849 14.9657 10.6184C14.9947 10.6519 15.0164 10.6912 15.0292 10.7336C15.0421 10.7761 15.0459 10.8207 15.0403 10.8647V10.8647Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_712_799">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
export default Facebook;