import React from 'react';
import { Field, Form } from 'react-final-form';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { sendOTP } from '../../../redux/actions/authAction';
import BackArrow from '../../../assets/svg/back_arrow';
import ProductImg from '../../../assets/svg/products_img';
import { SET_OTP_DATA } from '../../../constants/types';
import { removeMobileFomrmatter, toAbsoluteUrl } from '../../../utils';
import { renderNumberInput } from '../../../utils/FormField';
import { SigninValidate as validate } from '../../../utils/validate';
import LoaderBox from '../../components/common/Loader';

const TITLE = "Clefill - Sign In"

const Registere = ({ match }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const loader = useSelector(state => state.auth.loader);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    // Form Submit Method
    const onFormSubmit = (data) => {
        dispatch(sendOTP({ phoneNumber: removeMobileFomrmatter(data.phoneNumber), type: 'login' }))
            .then(res => {
                dispatch({
                    type: SET_OTP_DATA,
                    payload: { ...data, phoneNumber: removeMobileFomrmatter(data.phoneNumber), type: 'login' }
                })
                history.push('/otp/login')
            })
            .catch(err => {
                history.replace(`/signup?phoneNumber=${removeMobileFomrmatter(data.phoneNumber, true)}`)
                // swal('', err.message, 'error')
            })
    };
    if (isLoggedIn) {
        return <Redirect to="/" />
    }
    else
        return (
            <>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                {loader && <LoaderBox />}
                <main className="app-main">
                    <section className="auth-section">
                        <div className="back-arrow" onClick={(e) => {
                            e.preventDefault();
                            history.goBack()
                            dispatch({
                                type: SET_OTP_DATA,
                                payload: {}
                            })
                        }}> <BackArrow />  </div>
                        <Form
                            initialValues={match.params?.type === 'otp' ? { phoneNumber: '' } : {}}
                            onSubmit={onFormSubmit}
                            validate={validate}
                        >
                            {({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="img-area">
                                        <i> <ProductImg /> </i>
                                    </div>
                                    <div className="info-box">
                                        <h1>Let’s get you logged in</h1>
                                        {/* <p>If you have an account sign in with Registered phone number</p> */}
                                        <ul className="input-list">
                                            <li>
                                                <Field
                                                    name="phoneNumber"
                                                    component={renderNumberInput}
                                                    placeholder=' '
                                                    preFix="+91 "
                                                    type="text"
                                                    labelName="Enter your 10 digit mobile number"
                                                    inputClasses="floating__input"
                                                    labelClasses="floating__label"
                                                    containerClasses="floating form-grp"
                                                />
                                            </li>
                                        </ul>
                                        <button type='submit' className="links-btn">Continue</button>
                                    </div>
                                    <span className="small-info">By continuing, you agree to our
                                        <a href={toAbsoluteUrl(`/cms/tc`)} target={'_blank'} rel="noreferrer"> Terms </a>,
                                        <a href={toAbsoluteUrl(`/cms/rrd`)} target={'_blank'} rel="noreferrer">Refunds </a> and
                                        <a href={toAbsoluteUrl(`/cms/tc`)} target={'_blank'} rel="noreferrer"> Privacy Policy </a>
                                    </span>
                                    {/* <span className="small-info">Don't have an account?  <Link to="/signup">Register</Link> </span> */}
                                </form>)}
                        </Form>
                    </section>
                </main>
            </>
        );
}

export default Registere;