import React from 'react';


const WBackArrow= () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="19.084" height="18.598" viewBox="0 0 19.084 18.598">
                <defs>
                    <clipPath id="clipPath">
                    <path id="icon_navigation_arrow_forward_24px" data-name="icon/navigation/arrow_forward_24px" d="M1.225,10.524h13.68L8.928,16.5a1.234,1.234,0,0,0,0,1.739,1.22,1.22,0,0,0,1.727,0l8.071-8.071a1.22,1.22,0,0,0,0-1.727L10.667.358A1.221,1.221,0,0,0,8.941,2.085l5.964,5.989H1.225a1.225,1.225,0,1,0,0,2.449Z" transform="translate(0 0)" fill="#37474f"/>
                    </clipPath>
                </defs>
                <g id="icon_navigation_arrow_forward_24px-2" data-name="icon/navigation/arrow_forward_24px" transform="translate(19.084 18.598) rotate(180)">
                    <g id="Group_1" data-name="Group 1" transform="translate(0 0)">
                    <path id="icon_navigation_arrow_forward_24px-3" data-name="icon/navigation/arrow_forward_24px" d="M1.225,10.524h13.68L8.928,16.5a1.234,1.234,0,0,0,0,1.739,1.22,1.22,0,0,0,1.727,0l8.071-8.071a1.22,1.22,0,0,0,0-1.727L10.667.358A1.221,1.221,0,0,0,8.941,2.085l5.964,5.989H1.225a1.225,1.225,0,1,0,0,2.449Z" transform="translate(0 0)" fill="#37474f"/>
                    </g>
                </g>
            </svg>

        </>
    )
}
export default WBackArrow;