import axios from 'axios';
import * as ACTION_TYPES from '../../constants/types';

const initialState = {

  loader: false,
  otpData: {},

  //User Auth Data
  isLoggedIn: false,
  isValidLocation: false,
  locationName: '',
  validLocation: '',
  userData: {},
  activePincodes: [],

  // CMS details 
  cmsDetail: {},

  //User address 
  userAddress: [],
  editUserAddress: {},

  //User Profile
  userProfile: { address: [], profile: {} },

  message: '',
  error: null
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOADER_STATUS_CHANGE:
      return {
        ...state,
        loader: action.payload
      }
    case ACTION_TYPES.SET_OTP_DATA:
      return {
        ...state,
        otpData: action.payload
      }
    case ACTION_TYPES.LOGIN_SUCCESS:
      axios.defaults.headers.common['authorization'] = action.payload.data.token;
      localStorage.setItem('authTokenWebsite', JSON.stringify(action.payload.data.token));
      localStorage.setItem('userWebsite', JSON.stringify(action.payload.data));
      return {
        ...state,
        userData: action.payload.data,
        message: action.payload.message,
        isLoggedIn: true,
        error: null
      }
    case ACTION_TYPES.LOGIN_ERROR:
      return {
        ...state,
        message: action.payload.data.message,
        error: action.payload.data
      }
    case ACTION_TYPES.AUTHENTICATE_USER:
      return {
        ...state,
        isLoggedIn: true,
        userData: action.payload,
        error: null
      }
    case ACTION_TYPES.LOGOUT:
    case ACTION_TYPES.AUTHENTICATE_FAILED:
      axios.defaults.headers.common['authorization'] = '';
      localStorage.removeItem("authTokenWebsite");
      localStorage.removeItem("userWebsite");
      localStorage.removeItem("FCMToken");
      return {
        ...state,
        isLoggedIn: false,
        userData: {},
        userAddress: [],
        editUserAddress: {},
        userProfile: { address: [], profile: {} },
        message: '',
      }
    case ACTION_TYPES.VALID_LOCATION_SUCCESS:
      return {
        ...state,
        isValidLocation: true,
        validLocation: action.payload,
        error: null
      }
    case ACTION_TYPES.FETCH_LOCATION_FRM_PINCODE_SUCCESS:
      return {
        ...state,
        locationName: action.payload?.data,
        error: null
      }
    case ACTION_TYPES.VALID_LOCATION_ERROR:
      return {
        ...state,
        isValidLocation: false,
        validLocation: ''
      }
    case ACTION_TYPES.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload.data,
        error: null
      }
    case ACTION_TYPES.FETCH_PROFILE_ERROR:
      return {
        ...state,
        userProfile: { address: [], profile: {} },
      }
    case ACTION_TYPES.FETCH_ADDRESS_SUCCESS:
      return {
        ...state,
        userAddress: action.payload.data.list ? action.payload.data.list : [],
        error: null
      }
    case ACTION_TYPES.FETCH_ADDRESS_ERROR:
      return {
        ...state,
        userAddress: [],
      }
    case ACTION_TYPES.GET_CMS_SUCCESS:
      return {
        ...state,
        cmsDetail: action.payload.data,
        error: null
      }
    case ACTION_TYPES.GET_CMS_ERROR:
      return {
        ...state,
        cmsDetail: {},
      }
    case ACTION_TYPES.SET_EDIT_ADDRESS_SUCCESS:
      return {
        ...state,
        editUserAddress: action.payload,
        error: null
      }
    case ACTION_TYPES.GET_ACTIVE_AREA_LIST_S:
      return {
        ...state,
        activePincodes: action.payload?.data?.areaList?.length ? action.payload?.data?.areaList?.map((val) => val?.code) : [],
        error: null
      }
    case ACTION_TYPES.GET_ACTIVE_AREA_LIST_F:
      return {
        ...state,
        activePincodes: [],
        error: null
      }
    default:
      return state;
  }
}