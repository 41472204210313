import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';
import BackArrow from '../../../assets/svg/back_arrow';
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SET_OTP_DATA, SHLD_REDIRECT_TO_CART } from '../../../constants/types';
import { createDeviceId, displayPhoneNumber, getDeviceType, removeCountryCode, removeMobileFomrmatter } from '../../../utils';
import { createAcc, getProfile, onSignIn, sendOTP, verifyOTP } from '../../../redux/actions/authAction';
import { addCartLocalToApi, getCart, getCartCount } from '../../../redux/actions/productAction';
import swal from 'sweetalert';
import LoaderBox from '../../components/common/Loader';

const TITLE = "Clefill - OTP "

const Otp = ({ match }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const otpData = useSelector(state => state.auth.otpData)
    // console.log('otpData: ', otpData);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const shouldRedirectToCart = useSelector(state => state.product.shouldRedirectToCart)

    const [otpValue, setotpValue] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [Timer, setTimer] = useState(60);

    // Set countdown for otp 
    useEffect(() => {
        setTimeout(() => {
            if (Timer > 0) {
                setTimer(Timer - 1)
            }
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Timer])

    // Set otp value to local state
    const onOtpChange = (otp) => {
        setotpValue(otp)
    };

    // On Resend OTP
    const onResendOtp = () => {
        setTimer(60)
        setisLoading(true)
        dispatch(sendOTP({ phoneNumber: removeMobileFomrmatter(otpData?.phoneNumber), type: otpData?.type }))
            .then(res => {
                setTimer(60)
                setisLoading(false)
                swal('', res.message, 'success')
            })
            .catch(err => {
                setTimer(60)
                setisLoading(false)
                swal('', err.message, 'error')
            })
    };

    // Handle Otp submit method for signin and signup
    const handleOtpSubmit = (e) => {
        e.preventDefault();

        let ipAddress = JSON.parse(localStorage.getItem("deviceIp"));
        const FCMtoken = JSON.parse(localStorage.getItem('FCMToken'))
        const pincode = JSON.parse(localStorage.getItem("validLocation"));

        let pincodeLocal = '';
        if (pincode) {
            let tmpVar = pincode?.split(" ")
            if (tmpVar)
                pincodeLocal = tmpVar[0]
        }

        setisLoading(true)
        dispatch(verifyOTP({ otp: otpValue, phoneNumber: removeMobileFomrmatter(otpData.phoneNumber) }))
            // On successfully verified OTP 
            .then(res => {
                if (otpData?.type === 'register') {
                    dispatch(createAcc({ ...otpData, phoneNumber: removeMobileFomrmatter(otpData.phoneNumber), fcmToken: FCMtoken ? FCMtoken : '123455', deviceType: getDeviceType(), deviceId: createDeviceId(), ipAddress: ipAddress || '', pincode: pincodeLocal || '' }))
                        .then(res => {
                            dispatch({ type: SET_OTP_DATA, payload: {} })
                            // dispatch(getCart({ customerId: res?.data?.id }))
                            // dispatch(getCartCount({ customerId: res?.data?.id }))
                            setisLoading(false)
                            dispatch(getProfile())
                            if (shouldRedirectToCart) {
                                history.replace('/cart')
                                dispatch({ type: SHLD_REDIRECT_TO_CART, payload: false })
                            }
                            const localCartData = JSON.parse(localStorage.getItem('cart'))

                            // If local cart has any data then will be added to user cart api
                            if (localCartData && localCartData?.length) {
                                dispatch(addCartLocalToApi({ customerId: res?.data?.id, products: [...localCartData] }))
                                    .then(res2 => {
                                        dispatch(getCart({ customerId: res?.data?.id }))
                                        dispatch(getCartCount({ customerId: res?.data?.id }))
                                        if (localStorage.getItem('cart')) {
                                            localStorage.removeItem('cart')
                                        }
                                        if (localStorage.getItem('cartTotal')) {
                                            localStorage.removeItem('cartTotal')
                                        }
                                    })
                                    .catch(Err => {
                                        dispatch(getCart({ customerId: res?.data?.id }))
                                        dispatch(getCartCount({ customerId: res?.data?.id }))
                                    })
                            }
                            // If local cart has no cart data then will not be added to api
                            else {
                                dispatch(getCart({ customerId: res?.data?.id }))
                                dispatch(getCartCount({ customerId: res?.data?.id }))
                            }
                        })
                        .catch(err => {
                            setisLoading(false)
                            swal('', err.message, 'error')
                        })
                }
                else {
                    dispatch(onSignIn({ ...otpData, phoneNumber: removeMobileFomrmatter(otpData.phoneNumber), deviceType: getDeviceType(), deviceId: createDeviceId(), fcmToken: FCMtoken ? FCMtoken : '123455', ipAddress: ipAddress || '', pincode: pincodeLocal || '' }))
                        .then(res => {
                            dispatch({ type: SET_OTP_DATA, payload: {} })
                            // dispatch(getCart({ customerId: res?.data?.id }))
                            // dispatch(getCartCount({ customerId: res?.data?.id }))
                            setisLoading(false)
                            dispatch(getProfile())

                            const localCartData = JSON.parse(localStorage.getItem('cart'))
                            if (shouldRedirectToCart) {
                                history.replace('/cart')
                                dispatch({ type: SHLD_REDIRECT_TO_CART, payload: false })
                            }
                            // If local cart has any data then will be added to user cart api
                            if (localCartData && localCartData?.length) {
                                dispatch(addCartLocalToApi({ customerId: res?.data?.id, products: [...localCartData] }))
                                    .then(res2 => {
                                        dispatch(getCart({ customerId: res?.data?.id }))
                                        dispatch(getCartCount({ customerId: res?.data?.id }))
                                    })
                                    .catch(Err => {
                                        dispatch(getCart({ customerId: res?.data?.id }))
                                        dispatch(getCartCount({ customerId: res?.data?.id }))
                                    })
                            }
                            // If local cart has no cart data then will not be added to api
                            else {
                                dispatch(getCart({ customerId: res?.data?.id }))
                                dispatch(getCartCount({ customerId: res?.data?.id }))
                            }
                        })
                        .catch(err => {
                            setisLoading(false)
                            swal('', err.message, 'error')
                        })
                }
            })
            // On OTP verification error
            .catch(err => {
                setisLoading(false)
                swal('', err.message, 'error')
            })
    };
    if (isLoggedIn) {
        return <Redirect to="/" />
    }
    else
        return (
            <>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <main className="app-main">
                    <section className="auth-section otp-section">
                        <div className="auth-header d-flex justify-content-between align-items-center">
                            <div className="back-arrow" onClick={(e) => {
                                e.preventDefault();
                                history.push(`/${match.params.path}/otp`)
                            }}> <BackArrow />  </div>
                            <h1>OTP</h1>
                            {/* <h1>OTP Verification</h1> */}
                        </div>
                        {isLoading && <LoaderBox />}
                        <form>
                            <div className="info-box">
                                <h6>Please enter Verification code sent to <br />{`+91 ${displayPhoneNumber(removeCountryCode(otpData?.phoneNumber))}  `}<Link className='text-bold' to={`/${match.params.path}/otp`}>{` or Change phone number`}</Link> </h6>
                                <div className='otparea'>
                                    <OtpInput
                                        isInputNum={true}
                                        value={otpValue}
                                        onChange={onOtpChange}
                                        numInputs={6}
                                        shouldAutoFocus={true}
                                        containerStyle={'otp-container'}
                                        className="otp-box"
                                        separator={<span></span>}
                                    />
                                </div>
                                {Timer >= 1 && <span className="small-text">Resend in {Timer} seconds </span>}
                                {Timer < 1 && <button type='button' onClick={onResendOtp} className="links-btn">Resend OTP</button>}
                                {otpValue.length === 6 && <button type='submit' onClick={handleOtpSubmit} className="links-btn">Submit</button>}
                            </div>
                            {/* <span className="small-info">Don't have an account?  <Link to="/">Register</Link> </span> */}
                        </form>
                    </section>
                </main>
            </>
        );
}

export default Otp;