import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { webRoutes } from "../../../navigator/webRoutes";
import Header from "../common/header";
import Footer from "../common/footer";
import store from "../../../redux/store";
import {
  VALID_LOCATION_ERROR,
  VALID_LOCATION_SUCCESS,
} from "../../../constants/types";
import { useDispatch, useSelector } from "react-redux";
import LoaderBox from "../common/Loader";
import { getNotificationCount } from "../../../redux/actions/utilsAction";
import firebase from "firebase/app";
import { getToken } from "../../../firebase";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const WebLayout = (props) => {

    const { location } = props.history;
    const validLocation = JSON.parse(localStorage.getItem("validLocation"));

    const dispatch = useDispatch();

    const [isRender, setisRender] = useState(false)
    const [locationModal, setLocationModal] = useState(false);
    const [signinModal, setsigninModal] = useState(false);
    const [showFooter, setshowFooter] = useState(true);
    const history = useHistory()
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const isValidLocation = useSelector(state => state.auth.isValidLocation)
    const userData = useSelector(state => state.auth.userData)
    const loader = useSelector(state => state.auth.loader);

    if (validLocation) {
        store.dispatch({ type: VALID_LOCATION_SUCCESS, payload: validLocation })
    }
    else {
        store.dispatch({ type: VALID_LOCATION_ERROR })
    }

    useEffect(() => {
        if (firebase.messaging.isSupported() && !isLoggedIn) {
            if (!JSON.parse(localStorage.getItem("FCMToken")))
                getToken()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, location.pathname])

    useEffect(() => {

        if (location.pathname.startsWith('/')) {
            setisRender(false)
        }
        else if (location.pathname.startsWith('/product_list')) {
            setisRender(false)
        }
        else {
            setisRender(true)
        }

        if (location.pathname.startsWith('/cart')) {
            setshowFooter(false)
        }
        else {
            setshowFooter(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])


    useEffect(() => {
        const abortController = new AbortController()
        dispatch(getNotificationCount())

        return () => {
            abortController.abort();
        }
    }, [dispatch])

    return (
        <>
            <Header signinModal={signinModal} setsigninModal={setsigninModal} />
            {loader && <LoaderBox />}
            <section className={`wsection-area ${history?.location?.pathname === '/' ? "home-page-header" : ""}${isRender ? ' add_section-area' : ''} `}>
                <Switch>
                    {webRoutes.map((route, idx) => {
                        return route.element ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <route.element {...props}
                                        isLoggedIn={isLoggedIn}
                                        dispatch={dispatch}
                                        setLocationModal={setLocationModal}
                                        locationModal={locationModal}
                                        setsigninModal={setsigninModal}
                                        customerId={userData?.id}
                                    />
                                )}
                            />
                        ) : (null)
                    })}
                    <Route path="*" render={() => <Redirect to="/" push={false}/>} />
                    <Redirect to={`${isValidLocation ? '/product_list/prefill/all' : '/'}`} />
                </Switch>
            </section>
            {showFooter ? <Footer setLocationModal={setLocationModal}
                locationModal={locationModal} /> : ''}
        </>
    )
}

export default WebLayout;