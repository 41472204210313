import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { toCheckTokenValid } from '../../redux/actions/utilsAction';

const PaymentLink = ({ match }) => {

    const { orderId, token } = match.params;

    const dispatch = useDispatch();
    const history = useHistory();

    const [razorpayData, setrazorpayData] = useState({});

    useEffect(() => {
        dispatch(toCheckTokenValid(orderId, token))
            .then(res => {
                if (res.data?.paid) {
                    swal("", 'Payment is already done !', "error")
                    history.replace('/')
                }
                else {
                    if (res.data?.razorOrder?.id && res.data?.razorOrder?.amount) {
                        setrazorpayData(res.data?.razorOrder)
                        document.getElementById("submitButton").click();
                    }
                    else {
                        swal("", 'Oops , something went wrong !', "error")
                        history.replace('/')
                    }
                }
            })
            .catch(err => {
                swal("", err.message, "error")
                history.replace('/')
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        // <div>PaymentLink</div>
        <div className="add-btn process-btn fix-box">
            <form method="POST" className='d-none' action="https://api.razorpay.com/v1/checkout/embedded">
                <input type="hidden" name="key_id" value={process.env.REACT_APP_RAZORPAY_ID_KEY} />
                <input type="hidden" name="amount" value={razorpayData?.amount} />
                <input type="hidden" name="order_id" value={razorpayData?.id} />
                <input type="hidden" name="name" value="Clefill" />
                <input type="hidden" name="description" value="Thank you for choosing us . " />
                <input type="hidden" name="image" value={process.env.REACT_APP_RAZORPAY_IMG_URL} />
                {/* <input type="hidden" name="prefill[name]" value={`${userData?.firstName} ${userData?.lastName}`} /> */}
                {/* <input type="hidden" name="prefill[contact]" value={removeCountryCode(userData.phoneNumber)} /> */}
                {/* <input type="hidden" name="prefill[email]" value={userData?.email ? userData?.email : ''} /> */}
                <input type="hidden" name="notes[shipping address]" value=" 1101, 1102, 1103,1104 Aaron Spectra, Opp. Rajpath Club, Rajpath Rangoli Road, Bodakdev, Ahmedabad - 380054, Gujarat, India" />
                <input type="hidden" name="callback_url" value={process.env.REACT_APP_RAZORPAY_GENERATE_LINK_SUCCESS_URL} />
                <input type="hidden" name="cancel_url" value={process.env.REACT_APP_RAZORPAY_GENERATE_LINK_CANCEL_URL} />
                <button id='submitButton' >Submit</button>
            </form>
        </div>
    )
}

export default PaymentLink