import React from 'react'
import "../../../assets/scss/mobile/component/loader.scss";
import ScaleLoader from "react-spinners/ScaleLoader";

const LoaderBox = () => {

    return (
        <>
            <div className="loader-wrapper">
                <ScaleLoader color={'black'} width={10} height={55} speedMultiplier={0.7} margin={2} radius={2} size={150} />
            </div>
        </>
    )
}

export default LoaderBox;