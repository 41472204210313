import React from 'react';


const Back_arrow= () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="10.291" height="18" viewBox="0 0 10.291 18">
                <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M14.353,15.191l6.811-6.805a1.286,1.286,0,1,0-1.822-1.817L11.625,14.28a1.284,1.284,0,0,0-.038,1.774l7.748,7.765A1.286,1.286,0,0,0,21.158,22Z" transform="translate(-11.251 -6.194)" fill="#414042"/>
            </svg>

        </>
    )
}
export default Back_arrow;