import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom';

export default function Auth(ComposedComponent) {

  const Authentication = props => {
    const isLoginState = useSelector(state => state.auth.isLoggedIn)
    const isValidLocation = useSelector(state => state.auth.isValidLocation)

    // const userData = useSelector(state => state.login.userData)
    const { location } = props.history;

    let authPath = ['/add_address', '/shipping_address', '/my_address', '/account_dashboard', '/payment_method']

    useEffect(() => {
      if (!isValidLocation) {
        return props.history.push('/')
      }

      authPath.map((val, i) => {
        return ((location.pathname.startsWith(val) && !isLoginState) && props.history.push('/'))
      })

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoginState, isValidLocation])

    return <ComposedComponent {...props} />

  }

  return withRouter(Authentication)
}
