import {
    API_LIST_PRODUCT,
    API_DETAILS_PRODUCT,
    API_ADD_TO_CART,
    API_REMOVE_PROD_FROM_CART,
    API_UPDATE_PROD_QTY,
    API_EMPTY_CART,
    API_GET_CART,
    API_GET_CART_COUNT,
    API_GET_PROD_TYPE,
    API_GET_PROD_CAT,
    API_GET_CARD_LIST,
    API_DELETE_CARD,
    API_EDIT_CARD,
    API_CART_LOCAL_TO_API,
    VISITOR_CALL,
    SHOW_COUPON_LIST
} from "../../constants/api";
import {
    API, PROD_LIST_SUCCESS, PROD_LIST_ERROR, PROD_DETAIL_SUCCESS, PROD_DETAIL_ERROR, GET_CART_SUCCESS, GET_CART_ERROR, GET_CART_COUNT_SUCCESS, GET_CART_COUNT_ERROR, PROD_TYPE_SUCCESS, PROD_TYPE_ERROR, PROD_CATEGORY_SUCCESS, PROD_CATEGORY_ERROR, GET_CARD_SUCCESS, GET_CARD_ERROR
} from "../../constants/types";

export const productList = (data) => ({
    type: API,
    payload: {
        url: API_LIST_PRODUCT,
        method: 'POST',
        data: data,
        hideLoader: true,
        success: (data) => ({
            type: PROD_LIST_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: PROD_LIST_ERROR,
            payload: data
        })
    }
})

export const visitorCall = (data) => ({
    type: API,
    payload: {
        url: VISITOR_CALL,
        method: 'POST',
        data: data,
    }
})

export const productDetails = (data) => ({
    type: API,
    payload: {
        url: API_DETAILS_PRODUCT,
        method: 'POST',
        data: data,
        hideLoader: true,
        success: (data) => ({
            type: PROD_DETAIL_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: PROD_DETAIL_ERROR,
            payload: data
        })
    }
})

//get product Type
export const getProductType = (data) => ({
    type: API,
    payload: {
        url: API_GET_PROD_TYPE,
        method: 'GET',
        data: data,
        hideLoader: true,
        success: (data) => ({
            type: PROD_TYPE_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: PROD_TYPE_ERROR,
            payload: data
        })
    }
})
export const getCouponList = (data) => ({
    type: API,
    payload: {
        url: SHOW_COUPON_LIST,
        method: 'GET',
        data: data,
        hideLoader: true,
        success: (data) => ({
            type: PROD_TYPE_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: PROD_TYPE_ERROR,
            payload: data
        })
    }
})



// get product category
export const getProductCategory = (data) => ({
    type: API,
    payload: {
        url: API_GET_PROD_CAT,
        method: 'GET',
        data: data,
        hideLoader: true,
        success: (data) => ({
            type: PROD_CATEGORY_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: PROD_CATEGORY_ERROR,
            payload: data
        })
    }
})

export const getCart = (data) => ({
    type: API,
    payload: {
        url: API_GET_CART,
        method: 'POST',
        data: data,
        hideLoader: true,
        success: (data) => ({
            type: GET_CART_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_CART_ERROR,
            payload: data
        })
    }
})
// get cart count
export const getCartCount = (data) => ({
    type: API,
    payload: {
        url: API_GET_CART_COUNT,
        method: 'POST',
        data: data,
        hideLoader: true,
        success: (data) => ({
            type: GET_CART_COUNT_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_CART_COUNT_ERROR,
            payload: data
        })
    }
})

// Add to Cart
export const addCartLocalToApi = (data) => ({
    type: API,
    payload: {
        url: API_CART_LOCAL_TO_API,
        method: 'POST',
        data: data,
    }
})

// Add to Cart
export const addToCart = (data) => ({
    type: API,
    payload: {
        url: API_ADD_TO_CART,
        method: 'POST',
        data: data,
    }
})

// Remove Product to Cart
export const removeProductFromCart = (data) => ({
    type: API,
    payload: {
        url: API_REMOVE_PROD_FROM_CART,
        method: 'POST',
        data: data,
    }
})

// Update prod qty in Cart
export const updateProdQty = (data) => ({
    type: API,
    payload: {
        hideLoader: true,
        url: API_UPDATE_PROD_QTY,
        method: 'POST',
        data: data,
    }
})

// empty user Cart
export const emptyCart = (data) => ({
    type: API,
    payload: {
        url: API_EMPTY_CART,
        method: 'POST',
        data: data,
    }
})

// Payment api

//Get Card List
export const getCardList = (data) => ({
    type: API,
    payload: {
        url: API_GET_CARD_LIST,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_CARD_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_CARD_ERROR,
            payload: data
        })
    }
})

//Add Card 
export const addCard = (data) => ({
    type: API,
    payload: {
        url: API_GET_CART,
        method: 'POST',
        data: data,
    }
})

//Delete Card 
export const deleteCard = (data) => ({
    type: API,
    payload: {
        url: API_DELETE_CARD,
        method: 'POST',
        data: data,
    }
})

//Edit Card 
export const editCard = (data) => ({
    type: API,
    payload: {
        url: API_EDIT_CARD,
        method: 'PUT',
        data: data,
    }
})
