import React from 'react';
import { Helmet } from "react-helmet";
import ProductImg from '../../../assets/svg/products_img';
import { renderInput, renderNumberInput } from '../../../utils/FormField';
import { CreateAcc as validate } from '../../../utils/validate';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { removeMobileFomrmatter } from '../../../utils';
import { sendOTP } from '../../../redux/actions/authAction';
import swal from 'sweetalert';
import { Form, Field } from 'react-final-form';
import LoaderBox from '../../components/common/Loader';
import queryString from 'query-string'
import { SET_OTP_DATA } from '../../../constants/types';

const TITLE = "Clefill - Create Account"

const Account = ({ match }) => {

    const parsedQuery = queryString.parse(window.location.search);

    const history = useHistory();
    const dispatch = useDispatch();

    // const [isAgreed, setisAgreed] = useState(false)

    const isLoginState = useSelector(state => state.auth.isLoggedIn)
    const otpData = useSelector(state => state.auth.otpData)
    const loader = useSelector(state => state.auth.loader);

    // useEffect(() => {
    //     if (match.params?.type === 'otp') {
    //         setisAgreed(true)
    //     }
    // }, [match.params.type])

    // Form Submit Method
    const onFormSubmit = (data) => {
        dispatch(sendOTP({ phoneNumber: removeMobileFomrmatter(data.phoneNumber), type: 'register' }))
            .then(res => {
                dispatch({
                    type: SET_OTP_DATA,
                    payload: { ...data, phoneNumber: removeMobileFomrmatter(data.phoneNumber), type: 'register' }
                })
                history.push('/otp/signup')
            })
            .catch(err => {
                swal('', err.message, 'error')
            })
    };

    if (isLoginState) {
        return <Redirect to="/" />
    }
    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            {loader && <LoaderBox />}
            <main className="app-main">
                <section className="auth-section account-section">
                    {/* <div className="back-arrow" onClick={(e) => {
                        e.preventDefault();
                        history.goBack()
                        dispatch({
                            type: SET_OTP_DATA,
                            payload: {}
                        })
                    }}>
                        <BackArrow />
                    </div> */}
                    <Form
                        initialValues={match.params?.type === 'otp' ? { ...otpData, } : { phoneNumber: parsedQuery?.phoneNumber || '' }}
                        onSubmit={onFormSubmit}
                        validate={validate}
                    >
                        {({ handleSubmit }) => (
                            <>
                                <form onSubmit={handleSubmit}>
                                    <div className="img-area">
                                        <i> <ProductImg /> </i>
                                    </div>
                                    <div className="info-box">
                                        <h1>Getting Started</h1>
                                        <p>Create a account to Continue !</p>
                                        <ul className="input-list">
                                            <li>
                                                <div className={`outer-line `}>
                                                    <Field
                                                        name="phoneNumber"
                                                        component={renderNumberInput}
                                                        placeholder=' '
                                                        // preFix="+91 "
                                                        type="text"
                                                        labelName="Enter your 10 digit mobile number"
                                                        inputClasses="floating__input"
                                                        labelClasses="floating__label"
                                                        containerClasses="floating form-grp"
                                                    />
                                                </div>
                                            </li>
                                            <li>
                                                <div className='col-grp p-0'>
                                                    <Field
                                                        name="firstName"
                                                        type="text"
                                                        component={renderInput}
                                                        placeholder=' '
                                                        labelName="First name"
                                                        inputClasses="floating__input"
                                                        labelClasses="floating__label"
                                                        containerClasses="floating form-grp user"
                                                    />
                                                </div>
                                                <div className='col-grp p-0'>
                                                    <Field
                                                        name="lastName"
                                                        type="text"
                                                        component={renderInput}
                                                        placeholder=' '
                                                        labelName="Last name"
                                                        inputClasses="floating__input"
                                                        labelClasses="floating__label"
                                                        containerClasses="floating form-grp user"
                                                    />
                                                </div>
                                            </li>
                                            <li>
                                                <Field
                                                    name="email"
                                                    type="email"
                                                    component={renderInput}
                                                    placeholder=' '
                                                    labelName="Email (optional)"
                                                    inputClasses="floating__input"
                                                    labelClasses="floating__label"
                                                    containerClasses="floating form-grp email"
                                                />
                                            </li>
                                            {/* <li className="check-grp check-boxs">
                                                <input type="checkbox" name="Save" checked={isAgreed} id="Save" onChange={(e) => { setisAgreed(e.target.checked) }} />
                                                <label htmlFor="Save">By Creating account, you agree to our <Link to="/cms/tc">Terms & Conditions</Link> </label>
                                            </li> */}
                                        </ul>

                                        <button type='submit' className="links-btn">Sign up</button>
                                    </div>
                                    {/* <span className="small-info">Already user ? <Link to="/login">Sign in </Link> </span> */}
                                </form>
                            </>)}
                    </Form>
                </section>
            </main>
        </>
    );
}

export default Account;