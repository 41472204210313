import React from 'react';

const Linkedin = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_714_775)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18 17.9888H15.5137V14.0978C15.5137 13.17 15.4972 11.9767 14.2215 11.9767C12.9277 11.9767 12.7305 12.9877 12.7305 14.0317V17.9888H10.2465V9.98775H12.63V11.082H12.6645C12.996 10.4528 13.8075 9.789 15.0173 9.789C17.535 9.789 18 11.4458 18 13.6005V17.9888ZM7.443 8.895C6.64425 8.895 6 8.2485 6 7.45275C6 6.657 6.64425 6.0105 7.443 6.0105C8.238 6.0105 8.88375 6.657 8.88375 7.45275C8.88375 8.2485 8.238 8.895 7.443 8.895ZM8.6865 17.9888H6.19725V9.98775H8.6865V17.9888ZM12 0C5.37225 0 0 5.37225 0 12C0 18.627 5.37225 24 12 24C18.6277 24 24 18.627 24 12C24 5.37225 18.6277 0 12 0Z" fill="#2E78B6" />
                </g>
                <defs>
                    <clipPath id="clip0_714_775">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
export default Linkedin;