import React from 'react'
import NumberFormat from 'react-number-format';
import Select from 'react-select'
import Autocomplete from "react-google-autocomplete";
import { findCityFromGoogleMap } from '.';

export const renderInput = ({
  input,
  type,
  placeholder,
  containerClasses,
  inputClasses,
  labelClasses,
  labelName,
  disabled,
  meta: {
    touched,
    error,
  }
}) => (
  <>
    <div className={containerClasses}>
      <input {...input} className={inputClasses} disabled={disabled} type={type} placeholder={placeholder} />
      <label htmlFor={labelName} className={labelClasses} data-content={labelName}><span className="hidden--visually">{labelName}</span></label>
    </div>
    <div className="input-error">
      {(touched && error) && <span className="mb-0 text-right text-danger" >{error}</span>}
    </div>
  </>
)


export const renderNumberInput = ({
  input,
  type,
  formatField = '+91 ##### #####',
  placeholder,
  containerClasses,
  inputClasses,
  labelClasses,
  preFix,
  mutator,
  labelName,
  disabled,
  extraClass,
  meta: {
    touched,
    error,
    active
  }
}) => (
  <>
    <div className={`${containerClasses} ${extraClass ? extraClass : ''}`}>
      <NumberFormat
        {...input}
        format={formatField}
        placeholder={placeholder}
        disabled={disabled}
        className={inputClasses}
        onValueChange={(e) => {
          if (input?.name === 'pincode') {
            if (active && mutator) {
              mutator('city', '')
              mutator('streetAddress2', '')
              mutator('street', '')
            }
          }
        }} />
      {/* <input {...input} className={inputClasses} disabled={disabled} type={type} placeholder={placeholder} /> */}
      <label htmlFor={labelName} className={labelClasses} data-content={labelName}><span className="hidden--visually">{labelName}</span></label>
    </div>
    <div className="input-error">
      {(touched && error) && <span className="mb-0 text-right text-danger" >{error}</span>}
    </div>
  </>
)

export function LocationSearchInput(
  {
    input,
    className,
    placeholder,
    containerClasses,
    mutator,
    disabled,
    setaddress,
    setlat,
    setlng,
    inputClasses,
    labelClasses,
    labelName,
    meta: { touched, error },
  }) {
  return (<>
    <div className={'floating form-grp location'}>
      <Autocomplete
        {...input}
        className={inputClasses}
        disabled={disabled}
        onChange={(e) => {
          if (!e.target.value) {
            setlat('')
            setlng('')
            setaddress('')
            mutator('city', '')
            mutator('pincode', '')
          }
          input.onChange(e)
        }}
        // onBlur={input.onBlur}
        // value={input.value}
        placeholder={placeholder}
        apiKey={process.env.REACT_APP_LOCATION_API_KEY}
        options={{
          types: [],
          componentRestrictions: { country: "in" },
          fields: ['ALL']
        }}
        onPlaceSelected={(place) => {
          input.onChange(place?.formatted_address)
          if (place.geometry) {
            setaddress(place?.formatted_address)
            setlat(place.geometry.location.lat())
            setlng(place.geometry.location.lng())
            let tmpCityName = findCityFromGoogleMap(place?.address_components, 'locality')
            let tmpZipCode = findCityFromGoogleMap(place?.address_components, 'postal_code')
            mutator('city', tmpCityName)
            mutator('apartment', place?.name)
            mutator('pincode', tmpZipCode)
          }
        }}
      />
      <label htmlFor={labelName} className={labelClasses + ' streetlabel '} data-content={labelName}><span className="hidden--visually">{labelName}</span></label>
    </div>
    <div className="input-error">
      {(touched && error) && <span className="mb-0 text-right text-danger" >{error}</span>}
    </div>
  </>)
}

export const renderSelect = ({
  input,
  placeholder,
  labelName,
  options,
  meta: {
    touched,
    error,
  }
}) => (
  <>
    <Select {...input} isSearchable={false} options={options} placeholder={placeholder} />
    <div className="input-error">
      {(touched && error) && <span className="mb-0 text-right text-danger" >{error}</span>}
    </div>
  </>
)

export const renderTextArea = ({
  input,
  placeholder,
  className,
  labelName,
  options,
  meta: {
    touched,
    error,
  }
}) => (
  <>
    <textarea {...input} className={className} placeholder={placeholder}></textarea>
    {/* <Select {...input} options={options} placeholder={placeholder} /> */}
    <div className="input-error">
      {(touched && error) && <span className="mb-0 text-right text-danger" >{error}</span>}
    </div>
  </>
)