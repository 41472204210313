import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import Right from '../../assets/svg/right';

const Success_msg = ({ match }) => {

    //  CH002 - changed path for fix site map url
    //  Auther -Abhishek
    //  Start Date -14-03-2022 & End Date -14-03-2022

    const history = useHistory();
    const { id, payment } = match.params;

    useEffect(() => {
        // if (payment === 'true' && id) {
        //     setsuccessModal(true)
        // }
        if (payment === 'false') {
            swal('', 'Payment Failed !', 'error')
            history.replace('/')
            // setsuccessModal(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params])

    return (
        <>
            <Helmet>
                <title>{payment !== 'false' ? 'Success' : 'Fail'}</title>
            </Helmet>
            <br />
            {(payment !== 'false' && payment === 'true') && <div className="auth-section order-section ">
                <div className="order-screen">
                    <figure>
                        <Right />
                    </figure>
                    {/* <h2>Your order {match.params.id} has been placed !</h2> */}
                    <h2>{`Your payment for order ${id ? id : ''} has been recived successfully!`}</h2>
                    {/* <p>Order number : {match.params.id}</p> */}
                    {/* <p></p> */}
                    <div className="success-btn">
                        <button onClick={(e) => { e.preventDefault(); history.replace('/') }} className="btn-add del-btn">Close</button>
                        <button onClick={(e) => { e.preventDefault(); history.replace('/product_list/prefill/all') }} className="btn-add ">Continue Shopping</button>
                    </div>
                </div>
            </div>}
        </>
    );
}

export default Success_msg;